import React, { Component } from "react";
import PropTypes from "prop-types";
class Turno extends Component {
  static propTypes = {
    turno: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  };

  state = {
    horario: [],
  };

  componentDidMount = () => {
    let horario = this.props.turno.horario;

    let arr = horario.split("|");

    this.setState({ horario: arr });
  };
  
  render() {
    const { turno, index } = this.props;
    const { horario } = this.state;
    return (
      <div>
        <div key={index}>
          <div className="row">
            <div className="col-sm-6">
              <label>
                <b> {turno.turno}° turno </b>
              </label>
            </div>
            <div className="col-sm-6">{"  "}</div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="horaInicio" style={{ marginTop: "10px" }}>
                  Hora Início:
                </label>
                <input
                  type="time"
                  className="form-control input-md"
                  name="horaInicio"
                  readOnly
                  defaultValue={horario[0]}
                />
              </div>
            </div>
            <div className="col-sm-6">{"  "}</div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="horaFim" style={{ marginTop: "10px" }}>
                  Hora Fim:
                </label>
                <input
                  type="time"
                  className="form-control input-md"
                  name="horaFim"
                  readOnly
                  defaultValue={horario[1]}
                />
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  }
}

export default Turno;
