import React from "react";
import "./Empresas.css";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast, { Toaster } from "react-hot-toast";
import FormEstabelecimento from "./FormEstabelecimento";

class Empresas extends React.Component {
  state = {
    estabelecimentos: [],
    showCampos: false,
    selectedEstabelecimentoId: null,
    isEditing: false,
    searchQuery: "",
    isLoading: false,
  };
  componentDidMount() {
    this.getAllEstabelecimentos();
  }

  getAllEstabelecimentos = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await PrestAPI.get("Estabelecimento");
      this.setState({
        estabelecimentos: res.estabelecimentos,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error(error.message || "Erro ao buscar os estabelecimentos");
    }
  };

  toggleForm = (isEditing = false, estabelecimentoId = null) => {
    this.setState({
      showCampos: true,
      isEditing,
      selectedEstabelecimentoId: estabelecimentoId,
    });
  };

  handleCancel = () => {
    this.setState({ showCampos: false });
  };

  handleDelete = async (id) => {};

  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  render() {
    const {
      estabelecimentos,
      showCampos,
      selectedEstabelecimentoId,
      isEditing,
      searchQuery,
      isLoading,
    } = this.state;

    const filteredEstabelecimentos = estabelecimentos.filter(
      (estabelecimento) =>
        estabelecimento.razao_social
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );

    return (
      <section className="container-fluid">
        <div className="section-empresas">
          {!showCampos && (
            <button
              type="button"
              className="btn btn-default btn-vk"
              onClick={() => this.toggleForm(false)}
            >
              Cadastrar Nova Empresa
            </button>
          )}
          {showCampos && (
            <FormEstabelecimento
              estabelecimentoId={selectedEstabelecimentoId}
              isEditing={isEditing}
              onCancel={this.handleCancel}
            />
          )}
          <div className="row" style={{ margin: "15px 0" }}>
            <div className="col-md-6">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="Pesquisar"
                  value={searchQuery}
                  onChange={this.handleSearchChange}
                />
                <div className="input-group-btn">
                  <button className="btn btn-vk">Pesquisar</button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive" style={{ marginTop: 20 }}>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="4">
                      <div className="loader" id="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  filteredEstabelecimentos.map((estabelecimento) => (
                    <tr key={estabelecimento.id_estabelecimento}>
                      <td>
                        <svg
                          onClick={() =>
                            this.toggleForm(
                              true,
                              estabelecimento.id_estabelecimento
                            )
                          }
                          viewBox="0 0 48 48"
                          width="20"
                          aria-hidden="true"
                          focusable="false"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          color="#FF5722"
                          aria-label="edit"
                          className="StyledIconBase-sc-ea9ulj-0 kczbJK"
                          style={{ cursor: "pointer" }}
                        >
                          <path d="M32.2 6.03a6.9 6.9 0 1 1 9.77 9.76l-2.2 2.2L30 8.24l2.2-2.2ZM28.24 10 8.03 30.2a6 6 0 0 0-1.56 2.76l-2.43 9.48a1.25 1.25 0 0 0 1.52 1.52l9.49-2.42a6 6 0 0 0 2.76-1.58L38 19.76 28.22 10Z"></path>
                        </svg>
                        <svg
                          onClick={() => this.handleDelete(estabelecimento.id)}
                          viewBox="0 0 16 16"
                          width="20"
                          aria-hidden="true"
                          focusable="false"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          color="#FF5722"
                          aria-label="bin"
                          className="StyledIconBase-sc-ea9ulj-0 kczbJK"
                          style={{ cursor: "pointer" }}
                        >
                          <path d="M2 5v10c0 .55.45 1 1 1h9c.55 0 1-.45 1-1V5H2zm3 9H4V7h1v7zm2 0H6V7h1v7zm2 0H8V7h1v7zm2 0h-1V7h1v7zM13.25 2H10V.75A.753.753 0 0 0 9.25 0h-3.5A.753.753 0 0 0 5 .75V2H1.75a.752.752 0 0 0-.75.75V4h13V2.75a.752.752 0 0 0-.75-.75zM9 2H6v-.987h3V2z"></path>
                        </svg>
                      </td>
                      <td>{estabelecimento.razao_social}</td>
                      <td>{estabelecimento.email}</td>
                      <td>{estabelecimento.telefone}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Toaster />
      </section>
    );
  }
}

export default Empresas;
