import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import ModalComponentItem from "./ModalComponentItem";
import ModalCategoriaComplement from "./ModalCategoriaComplement";

class CategoriaComplementList extends Component {
  static propTypes = {
    categoria: PropTypes.object,
    deleteCatComplemento: PropTypes.func,
    getCatComplementos: PropTypes.func,
  };

  state = {
    isModalVisible: false,
    isNovo: false,
  };

  showNovo = () => {
    this.setState({ isNovo: true });
  };

  hideNovo = () => {
    this.setState({ isNovo: false });
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

  componentDidMount = () => {
    let categoria = this.props.categoria;

    document
      .getElementById(`edita${categoria.id_categoria_complemento}`)
      .addEventListener("click", (e) => {
        this.showModal();

        e.stopPropagation();
      });

    document
      .getElementById(`novoComp${categoria.id_categoria_complemento}`)
      .addEventListener("click", (e) => {
        this.showNovo();

        e.stopPropagation();
      });
  };

  render() {
    const { categoria } = this.props;
    const { isModalVisible, isNovo } = this.state;

    return (
      <Fragment>
        <div
          className="panel-heading"
          data-toggle="collapse"
          href={`#collapse${categoria.id_categoria_complemento}`}
          style={{
            cursor: "pointer",
            borderBottom: "1px solid #ddd",
          }}
        >
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div className="col-md-7">
              <h3>{categoria.nome_categoria}</h3>
            </div>
            <div className="col-md-2">
              <button
                className="btn btn-vk btn-block "
                id={`edita${categoria.id_categoria_complemento}`}
              >
                Editar grupo
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-vk btn-block"
                id={`novoComp${categoria.id_categoria_complemento}`}
              >
                Adicionar Complemento
              </button>
            </div>
            <div className="col-md-8 text-muted">
              <h4>
                {categoria.descricao_categoria === ""
                  ? "Grupo sem descrição"
                  : categoria.descricao_categoria}
              </h4>
            </div>
            <div className="col-md-2">
              <h5>Qtde Min:{<b>{` ${categoria.qtde_min}`}</b>}</h5>
            </div>
            <div className="col-md-2">
              <h5>Qtde Max:{<b>{` ${categoria.qtde_maxima}`}</b>}</h5>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={isModalVisible}
          style={customStyles}
          contentLabel="Modal Complemento Item"
          onRequestClose={this.hideModal}
        >
          <ModalCategoriaComplement
            categoria={categoria}
            deleteCatComplemento={(idEstabelecimento, idProduto, idCategoria) =>
              this.props.deleteCatComplemento(
                idEstabelecimento,
                idProduto,
                idCategoria
              )
            }
            getCatComplementos={(categoria) =>
              this.props.getCatComplementos(categoria)
            }
            showModal={this.showModal}
            hideModal={this.hideModal}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={isNovo}
          style={customStyles}
          contentLabel="Modal Complemento Item"
          onRequestClose={this.hideNovo}
        >
          <ModalComponentItem
            modo={"novo"}
            ocultar={true}
            item={categoria}
            onClosed={() => {
              this.hideNovo();
              this.props.getCatComplementos(categoria);
            }}
            getCatComplementos={(categoria) =>
              this.props.getCatComplementos(categoria)
            }
          />
        </Modal>
      </Fragment>
    );
  }
}

export default CategoriaComplementList;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};
