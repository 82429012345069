import React from "react";
import PropTypes from "prop-types";

const resizeImageFile = (file, maxHeight) =>
  new Promise((resolve) => {
    const image = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      image.src = event.target.result;
    };

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (image.height > maxHeight) {
        image.width *= maxHeight / image.height;
        image.height = maxHeight;
      }

      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);

      canvas.toBlob((blob) => {
        const resizedFile = new File([blob], file.name, { type: file.type });
        resolve(resizedFile);
      }, file.type);
    };

    reader.readAsDataURL(file);
  });

class ImageInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    maxHeight: PropTypes.number,
    onImageChange: PropTypes.func.isRequired,
  };

  state = {
    value: this.props.defaultValue || "",
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.match(/^image\//)) {
      resizeImageFile(file, this.props.maxHeight).then((resizedFile) => {
        const objectUrl = URL.createObjectURL(resizedFile);
        this.setState({ value: objectUrl });

        if (this.props.onImageChange) {
          this.props.onImageChange(resizedFile);
        }
      });
    } else {
      this.setState({ value: "" });
    }
  };

  handleFormReset = () => {
    this.setState({ value: this.props.defaultValue || "" });
  };

  componentDidMount() {
    this.canvas = document.createElement("canvas");
    this.fileInput.form.addEventListener("reset", this.handleFormReset);
  }

  componentWillUnmount() {
    this.fileInput.form.removeEventListener("reset", this.handleFormReset);
  }

  render() {
    const { className, name } = this.props;
    const { value } = this.state;

    const style = {
      position: "relative",
      backgroundImage: `url("${value}")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };

    return (
      <div className={className} style={style}>
        <input type="hidden" name={name} value={value} />
        <input
          ref={(node) => (this.fileInput = node)}
          type="file"
          onChange={this.handleFileChange}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
          }}
        />
      </div>
    );
  }
}

export default ImageInput;
