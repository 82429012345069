import React, { Component } from "react";
import ComponentItem from "./ComponentItem";
import PropTypes from "prop-types";
// import Modal from "react-modal";

import NewCategoriaComplement from "./NewCategoriaComplement";

import "./styles.css";
import CategoriaComplementList from "./CategoriaComplementList";

class CatComponent extends Component {
  static propTypes = {
    idProduto: PropTypes.number,
    idEstabelecimento: PropTypes.number,
    complementos: PropTypes.array,
    getProdutoComplemento: PropTypes.func,
    deleteCatComplemento: PropTypes.func,
    selectComp: PropTypes.func,
  };

  state = {
    ocultaCategoria: true,
    ocultaComplementoItem: true,
    isModalVisible: false,
    statusCat: "Ativo",
    statusComp: "Ativo",
  };

  exibeCategoriaComponent = () => {
    this.setState({ ocultaCategoria: false });
  };

  ocultaCategoriaComponent = () => {
    this.setState({ ocultaCategoria: true });
  };

  showModalComplement = () => {
    this.setState({ isModalVisible: true });
  };

  componentDidMount() {
    this.setState({ statusCat: "Ativo", statusComp: "Ativo" });
  }

  render() {
    const {
      idProduto,
      idEstabelecimento,
      getProdutoComplemento,
      complementos,
    } = this.props;
    const { ocultaCategoria, statusCat, statusComp } = this.state;

    return (
      <div className="container-fluid">
        <br />
        <div className="col-sm-3">
          <button
            onClick={
              ocultaCategoria
                ? this.exibeCategoriaComponent
                : this.ocultaCategoriaComponent
            }
            className={` ${
              ocultaCategoria ? " btn btn-vk btn-block" : "btnExc"
            } `}
            style={ocultaCategoria ? { outline: "none" } : { width: "100%" }}
          >
            {ocultaCategoria ? <>Adicionar grupo (Título)</> : <b>X</b>}
          </button>
        </div>
        <div className="col-sm-2" style={{ textAlign: "end" }}>
          <label>Status Grupo:</label>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <select
              className="form-control"
              id="statusFiltro"
              onChange={(e) => {
                this.setState({ statusCat: e.target.value });
              }}
            >
              <option>Ativo</option>
              <option>Inativo</option>
              <option>Todos</option>
            </select>
          </div>
        </div>
        <div className="col-sm-3" style={{ textAlign: "end" }}>
          <label>Status Complemento:</label>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <select
              className="form-control"
              id="statusFiltro"
              onChange={(e) => {
                this.setState({ statusComp: e.target.value });
              }}
            >
              <option>Ativo</option>
              <option>Inativo</option>
              <option>Todos</option>
            </select>
          </div>
        </div>

        <br />
        {ocultaCategoria ? null : (
          <NewCategoriaComplement
            idEstabelecimento={idEstabelecimento}
            idProduto={idProduto}
            ocultaCategoria={ocultaCategoria}
            ocultaCategoriaComponent={this.ocultaCategoriaComponent}
            getCatComplementos={(i) => {
              getProdutoComplemento(i);
            }}
          />
        )}
        <br />
        <div
          className="panel-group"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
          onClick={this.teste}
        >
          <div className="panel-group">
            <div className="container-fluid">
              {complementos === null || complementos === undefined
                ? null
                : complementos
                    .filter((categoria) => {
                      if (statusCat === "Ativo") {
                        return categoria.status_categoria === 1;
                      } else if (statusCat === "Inativo") {
                        return categoria.status_categoria === 0;
                      } else {
                        return categoria;
                      }
                    })
                    .map((categoria) => (
                      <div
                        className="panel panel-default"
                        style={{ marginTop: "20px" }}
                        key={categoria.id_categoria_complemento}
                      >
                        <CategoriaComplementList
                          categoria={categoria}
                          deleteCatComplemento={() =>
                            this.props.deleteCatComplemento(
                              idEstabelecimento,
                              idProduto,
                              categoria.id_categoria_complemento
                            )
                          }
                          getCatComplementos={(i) => {
                            this.props.getProdutoComplemento(i);
                          }}
                        />
                        <div
                          id={`collapse${categoria.id_categoria_complemento}`}
                          className="panel-collapse collapse in"
                        >
                          {categoria.itens !== null
                            ? categoria.itens
                                .filter((item) => {
                                  if (statusComp === "Ativo") {
                                    return item.status_complemento === 1;
                                  } else if (statusComp === "Inativo") {
                                    return item.status_complemento === 0;
                                  } else {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <div
                                    key={item.id_complemento}
                                    className="complement-list-items"
                                  >
                                    <ComponentItem
                                      ocultar={true}
                                      item={item}
                                      getCatComplementos={(item) => {
                                        this.props.getProdutoComplemento(item);
                                      }}
                                      selectComp={(complemento) =>
                                        this.props.selectComp(complemento)
                                      }
                                    />
                                  </div>
                                ))
                            : null}
                        </div>
                      </div>
                    ))}
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default CatComponent;

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };
