import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import $ from "../node_modules/jquery/dist/jquery.min.js";
import { EstabelecimentoProvider } from "./context/EstabelecimentoContext.js";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("../node_modules/bootstrap/dist/js/bootstrap.min.js");

ReactDOM.render(
  <EstabelecimentoProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </EstabelecimentoProvider>,
  document.getElementById("root")
);
registerServiceWorker();
