import { PropTypes } from "prop-types";
import React, { Component } from "react";
import "./Relatorios.css";
class ModalRelatorio extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    subtotal: PropTypes.number.isRequired,
    itens: PropTypes.array,
  };

  state = {
    loading: false,
    documentoHide: "",
  };

  renderSwitch(statusPedido) {
    switch (statusPedido) {
      case "CONFIRMADO":
        return (
          <h4
            style={{
              border: "1px solid DeepSkyBlue",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Confirmado
          </h4>
        );
      case "ENVIADO":
        return (
          <h4
            style={{
              border: "1px solid gray",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Enviado
          </h4>
        );
      case "ENTREGUE":
        return (
          <h4
            style={{
              border: "1px solid green",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Entregue
          </h4>
        );
      case "CANCELADO":
        return (
          <h4
            style={{
              border: "1px solid red",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Cancelado
          </h4>
        );
      default:
        return (
          <h4
            style={{
              border: "1px solid orange",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Pendente
          </h4>
        );
    }
  }

  hideDocument = () => {
    let documento = this.props.pedido.documento;

    if (documento !== null && documento !== undefined && documento !== "") {
      if (documento.length === 14) {
        documento = documento.substr(0, 5);
        documento = documento += "**.***-**";
        this.setState({ documentoHide: documento });
      } else {
        documento = documento.substr(0, 4);
        documento = documento += "**.***/****-**";
        this.setState({ documentoHide: documento });
      }
    } else {
      this.setState({ documentoHide: "" });
    }
  };

  loader = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  componentDidMount() {
    this.loader();
    setTimeout(() => {
      this.hideDocument();
    }, 500);
  }

  render() {
    const { pedido, subtotal, itens } = this.props;
    const { loading, documentoHide } = this.state;
    return (
      <>
        {!loading ? (
          <div className="modal-relatorio">
            <div
              className="panel panel-default col-sm-8 col-xs-12"
              style={{
                width: "100%",
                overflow: "hidden",
                padding: "30px 80px 30px 80px",
                border: "none",
                boxShadow: "none",
              }}
            >
              <div className="row">
                <div
                  className="col-sm-4"
                  style={{
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                  }}
                >
                  {this.renderSwitch(pedido.status_pedido)}
                </div>
                <div className="col-xs-4" style={{ textAlign: "center" }}>
                  <h3>
                    <b>{`Pedido ${pedido.id_pedido}`}</b>
                  </h3>
                  {pedido.tipo_pedido === "RETIRADA" ? (
                    <h4 style={{ color: "red" }}>Pedido para retirada</h4>
                  ) : null}
                </div>
                <div className="col-xs-4">
                  <h3 style={{ fontSize: "15px" }}>
                    {new Date(pedido.data_hora).toLocaleString("pt-BR", {
                      timeZone: "GMT",
                    })}
                  </h3>
                </div>
              </div>
              {pedido.id_pedido === null ||
              pedido.id_pedido === undefined ? null : (
                <div className="row">
                  <div className="col-sm-8" style={{ textAlign: "left" }}>
                    <h5 style={{ margin: "0" }}>
                      <b>Cliente:</b>
                      <div style={{ fontSize: "16px" }}>
                        {` ${pedido.nome_cliente}`}
                      </div>
                    </h5>
                  </div>
                  {pedido.telefone !== "" ? (
                    <div className="col-sm-4" style={{ textAlign: "left" }}>
                      <b>Telefone:</b>
                      <div style={{ fontSize: "15px" }}>{pedido.telefone}</div>
                    </div>
                  ) : null}
                  <div className="w-100"></div>
                  <div
                    className={
                      pedido.documento !== "" ? "col-sm-8" : "col-sm-12"
                    }
                    style={{ textAlign: "left" }}
                  >
                    <h5 style={{ marginTop: "10px", marginBottom: "0px" }}>
                      <b>Endereço:</b>
                      <div style={{ fontSize: "16px" }}>
                        {`${pedido.entrega_logradouro}, ${pedido.entrega_numero} `}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {`${pedido.entrega_bairro} - ${pedido.entrega_municipio}, ${pedido.entrega_uf} `}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {pedido.entrega_complemento !== ""
                          ? pedido.entrega_complemento
                          : null}
                      </div>
                    </h5>
                  </div>
                  {pedido.documento !== "" ? (
                    <div className="col-sm-4" style={{ textAlign: "left" }}>
                      <h5 style={{ marginTop: "10px", marginBottom: "0px" }}>
                        <b>Documento:</b>
                        <div style={{ fontSize: "16px" }}>{documentoHide}</div>
                      </h5>
                    </div>
                  ) : null}

                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                  <div className="container-fluid col-sm-12">
                    {itens.map((item) => (
                      <div
                        className="panel panel-default"
                        style={{
                          border: "none",
                          boxShadow: "none",
                          width: "100%",
                        }}
                        key={item.num_item}
                      >
                        <div className="panel-body" style={{ padding: "0" }}>
                          <div className="row">
                            <div
                              className="col-xs-10"
                              style={{ fontSize: "15px" }}
                            >{`${item.quantidade}x ${item.nome_produto}`}</div>
                            <div
                              className="col-xs-2"
                              style={{ textAlign: "end" }}
                            >
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(item.vr_unitario)}
                            </div>
                            {item.subItens === null ||
                            item.subItens === undefined ||
                            item.subItens === ""
                              ? null
                              : item.subItens.map((complemento) =>
                                  complemento === null ? null : (
                                    <div
                                      key={complemento.id_complemento}
                                      className="col-xs-12"
                                      style={{ paddingRight: "0" }}
                                    >
                                      <div
                                        className="col-xs-9 text-muted"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {` ${complemento.quantidade}x ${complemento.complemento}`}
                                      </div>
                                      <div
                                        className="col-xs-3 text-muted"
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "end",
                                        }}
                                      >
                                        {new Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(complemento.vr_adicional)}
                                      </div>
                                    </div>
                                  )
                                )}
                            <div className="col-xs-10"></div>
                            <div
                              className="col-xs-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                textAlign: "end",
                              }}
                            >
                              {item.vr_total_sub_itens !== null &&
                              item.vr_total_sub_itens !== undefined
                                ? new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(
                                    item.vr_unitario + item.vr_total_sub_itens
                                  )
                                : new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(item.vr_total)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      className="panel panel-default"
                      style={{
                        border: "none",
                        width: "100%",
                        boxShadow: "none",
                      }}
                    >
                      <div className="row">
                        <div className="col-xs-7"></div>
                        <div
                          className="col-xs-3"
                          style={{ fontSize: "16px", textAlign: "left" }}
                        >
                          <b>Subtotal:</b>
                        </div>
                        <div
                          className="col-xs-2"
                          style={{ fontSize: "16px", textAlign: "end" }}
                        >
                          <b>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(subtotal)}
                          </b>
                        </div>
                        <div className="col-xs-7"></div>
                        <div
                          className="col-xs-3"
                          style={{ fontSize: "16px", textAlign: "left" }}
                        >
                          <b>Taxa:</b>
                        </div>
                        <div
                          className="col-xs-2"
                          style={{ fontSize: "16px", textAlign: "end" }}
                        >
                          <b>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(pedido.entrega_taxa)}
                          </b>
                        </div>
                        <div className="col-xs-7"></div>
                        <div
                          className="col-xs-3"
                          style={{ fontSize: "16px", textAlign: "left" }}
                        >
                          <b>Total:</b>
                        </div>
                        <div
                          className="col-xs-2"
                          style={{ fontSize: "16px", textAlign: "end" }}
                        >
                          <b>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(pedido.vr_pedido)}
                          </b>
                        </div>
                        {pedido.vr_troco !== 0 ? (
                          <>
                            <div className="col-xs-7"></div>
                            <div
                              className="col-xs-3"
                              style={{
                                fontSize: "16px",
                                paddingRight: "0px",
                                textAlign: "left",
                              }}
                            >
                              <b>Troco para:</b>
                            </div>
                            <div
                              className="col-xs-2"
                              style={{ fontSize: "16px", textAlign: "end" }}
                            >
                              <b>
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(pedido.vr_troco)}
                              </b>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="panel panel-default"
                      style={{
                        border: "none",
                        width: "100%",
                        boxShadow: "none",
                      }}
                    >
                      <div className="col-xs-12">
                        <b>Forma de Pagamento:</b>
                      </div>
                      <div className="col-xs-12">{pedido.forma_pagamento}</div>
                      {pedido.entrega_observacao !== "" ? (
                        <>
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "left", marginTop: "36px" }}
                          >
                            <h5
                              style={{ marginTop: "10px", marginBottom: "0px" }}
                            >
                              <b>Observações:</b>
                            </h5>
                          </div>
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "left" }}
                          >
                            <div style={{ fontSize: "16px" }}>
                              {pedido.entrega_observacao}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                  <div
                    className="panel panel-default"
                    style={{
                      border: "none",
                      width: "100%",
                      boxShadow: "none",
                    }}
                  >
                    <div className="col-xs-1"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="loaderRela" id="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </>
    );
  }
}

export default ModalRelatorio;
