import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../../service/api/PrestAPI";
import serializeForm from "form-serialize";
import { toast } from "react-hot-toast";
import "./styles.css";

class ModalCategoriaComplement extends Component {
  static propTypes = {
    categoria: PropTypes.object.isRequired,
    deleteCatComplemento: PropTypes.func,
    getCatComplementos: PropTypes.func,
    hideModal: PropTypes.func,
  };

  handleSubmit = (e) => {
    let categoria = this.props.categoria;
    let status_categoria = document.getElementById("status_categoria");
    e.preventDefault();
    const form = e.target;
    const data = serializeForm(form, { hash: true });

    if (data.nome_categoria !== "") {
      if (data.qtde_maxima > 1) {
        data.modo_multiplo = 1;
      } else {
        data.modo_multiplo = 0;
      }

      if (data.qtde_min >= 1) {
        data.uso_obrigatorio = 1;
      } else {
        data.uso_obrigatorio = 0;
      }
      data.id_estabelecimento = Number.parseInt(
        categoria.id_estabelecimento,
        10
      );
      data.id_produto = Number.parseInt(categoria.id_produto, 10);
      data.qtde_maxima = Number.parseInt(data.qtde_maxima, 10);
      data.qtde_min = Number.parseInt(data.qtde_min, 10);
      data.id_categoria_complemento = Number.parseInt(
        categoria.id_categoria_complemento,
        10
      );
      data.sequencia = Number.parseInt(data.sequencia, 10);
      if (status_categoria.checked) {
        data.status_categoria = 1;
      } else {
        data.status_categoria = 0;
      }
      this.atualizaCat(data);
      this.props.hideModal();
    }
  };

  atualizaCat = (categoria) => {
    PrestAPI.update(
      "ProdutoComplemento",
      `?idCategoria=${categoria.id_categoria_complemento}`,
      categoria
    ).then((res) => {
      if (res.status === 200) {
        toast.success("Salvo!");
        this.props.getCatComplementos(categoria);
        this.props.hideModal();
      } else {
        toast.error(res.error);
      }
    });
  };

  state = {};

  render() {
    const { categoria } = this.props;

    return (
      <div>
        <div className="complement-list">
          <form onSubmit={this.handleSubmit}>
            <div className="col-sm-12 modal-complement-title">
              <b>Editar grupo</b>
            </div>
            <div className="form-group col-sm-12"></div>
            <div className="form-group col-sm-5">
              <label>Nome grupo</label>
              <input
                type="text"
                name="nome_categoria"
                defaultValue={categoria.nome_categoria}
                className="form-control input-sm"
                placeholder=""
              />
            </div>
            <div className="form-group col-sm-2">
              <label>Qtde Min</label>
              <input
                type="number"
                name="qtde_min"
                defaultValue={categoria.qtde_min}
                min="0"
                className="form-control input-sm"
                placeholder=""
                style={{ textAlign: "center" }}
              />
            </div>
            <div className="form-group col-sm-2">
              <label>Qtde Max</label>
              <input
                type="number"
                name="qtde_maxima"
                defaultValue={categoria.qtde_maxima}
                className="form-control input-sm"
                placeholder=""
                style={{ textAlign: "center" }}
              />
            </div>
            <div className="form-group col-sm-1">
              <label>Sequência</label>
              <input
                type="number"
                name="sequencia"
                defaultValue={categoria.sequencia}
                className="form-control input-sm"
                placeholder=""
                style={{ width: "50px", textAlign: "center" }}
              />
            </div>
            <div className="form-group col-sm-2">
              <div className="col-sm-12">
                <label htmlFor="status">Status:</label>
              </div>
              <div className="col-sm-12">
                <input
                  type="checkbox"
                  name="status_categoria"
                  defaultChecked={categoria.status_categoria === 1}
                  id="status_categoria"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginTop: "1px",
                  }}
                />
              </div>
            </div>
            <div className="form-group col-sm-5">
              <label>Descrição</label>
              <input
                type="text"
                name="descricao_categoria"
                defaultValue={categoria.descricao_categoria}
                className="form-control input-sm"
                placeholder=""
              />
            </div>
            {/* <div className="col-sm-5">
          <input
            type="checkbox"
            name="uso_obrigatorio"
            defaultChecked={item.uso_obrigatorio}
            defaultValue={item.uso_obrigatorio === "on" ? "1" : "0"}
            style={{ alignItems: "center" }}
          />{" "}
          Uso obrigatório{" "}
          <input
            type="checkbox"
            name="modo_multiplo"
            defaultChecked={item.modo_multiplo}
            defaultValue={item.modo_multiplo === "on" ? "1" : "0"}
            style={{ alignItems: "center" }}
          />{" "}
          Multiplo {""}
          <input
            type="checkbox"
            name="modo_cobranca"
            defaultChecked={item.modo_cobranca}
            defaultValue={item.modo_cobranca === "on" ? "1" : "0"}
            style={{ alignItems: "center" }}
          />{" "}
          Cobrar pelo mais caro
        </div> */}

            {/* <div className="col-sm-1">
          <div className="form-group col-sm-1" hidden={true}>
            <label>Estabelecimento</label>
            <input
              type="text"
              name="id_estabelecimento"
              defaultValue={item.estabelecimento}
            />
          </div>
          <div className="form-group col-sm-1" hidden={true}>
            <label>Produto</label>
            <input
              type="text"
              name="id_produto"
              defaultValue={item.id_produto}
            />
          </div>
          <div className="form-group col-sm-1" hidden={true}>
            <label>ID</label>
            <input
              type="text"
              name="id_categoria_complemento"
              defaultValue={item.id_categoria_complemento}
            />
          </div>
        </div> */}
            <div className="form-group col-sm-3" style={{ marginTop: "4px" }}>
              <label></label>
              <button className="form-control input-sm btn-vk">Salvar</button>
            </div>
          </form>
          <div className="form-group col-sm-3" style={{ marginTop: "4px" }}>
            <label></label>
            <button
              onClick={() => {
                this.props.deleteCatComplemento(
                  categoria.id_estabelecimento,
                  categoria.id_produto,
                  categoria.id_categoria_complemento
                );
                this.props.hideModal();
              }}
              className="form-control input-sm btnExc"
            >
              Excluir
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalCategoriaComplement;
