import React, { Component } from "react";
import "./Relatorios.css";
import { PropTypes } from "prop-types";

class PedidoRelatorio extends Component {
  static = {
    pedido: PropTypes.object.isRequired,
    showModal: PropTypes.function,
    hideModal: PropTypes.function,
  };

  renderSwitch(statusPedido) {
    switch (statusPedido) {
      case "CONFIRMADO":
        return (
          <div
            className="col-xs-2"
            style={{
              borderLeft: `solid 1.5px #e3e3e3`,
              textAlign: "center",
            }}
          >
            <div
              className="col-xs-12"
              style={{
                border: "1px solid DeepSkyBlue",
                borderRadius: "5px",
                textAlign: "center",
                margin: "20px, 0px, 10px",
                padding: "0px",
              }}
            >
              Confirmado
            </div>
          </div>
        );
      case "ENVIADO":
        return (
          <div
            className="col-xs-2"
            style={{ borderLeft: `solid 1.5px #e3e3e3` }}
          >
            <div
              className="col-xs-12"
              style={{
                border: "1px solid gray",
                borderRadius: "5px",
                textAlign: "center",
                margin: "20px, 0px, 10px",
                padding: "0px",
              }}
            >
              Enviado
            </div>
          </div>
        );
      case "ENTREGUE":
        return (
          <div className="col-xs-2" style={{ borderLeft: `solid 1.5px green` }}>
            <div
              className="col-xs-12"
              style={{
                border: "1px solid green",
                borderRadius: "5px",
                textAlign: "center",
                margin: "20px, 0px, 10px",
                padding: "0px",
              }}
            >
              Entregue
            </div>
          </div>
        );
      case "CANCELADO":
        return (
          <div
            className="col-xs-2"
            style={{ borderLeft: `solid 1.5px #8c2626` }}
          >
            <div
              className="col-xs-12"
              style={{
                border: "1px solid red",
                borderRadius: "5px",
                textAlign: "center",
                margin: "20px, 0px, 10px",
                padding: "0px",
              }}
            >
              Cancelado
            </div>
          </div>
        );
      default:
        return (
          <div
            className="col-xs-2"
            style={{ borderLeft: `solid 1.5px orange` }}
          >
            <div
              className="col-xs-12"
              style={{
                border: "1px solid orange",
                borderRadius: "5px",
                textAlign: "center",
                margin: "20px, 0px, 10px",
                padding: "0px",
              }}
            >
              Pendente
            </div>
          </div>
        );
    }
  }

  render() {
    const { pedido } = this.props;
    return (
      <div
        className="panel panel-default clickable panelPedido"
        style={{
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          MsUserSelect: "none",
          userSelect: "none",
          marginBottom: "5px",
          padding: "5px 15px",
          backgroundColor: `${
            pedido.status_pedido === "PENDENTE"
              ? "#fcee9f"
              : pedido.status_pedido === "ENTREGUE"
              ? "#abfc8b"
              : pedido.status_pedido === "CANCELADO"
              ? "#fc9797"
              : ""
          }`,
        }}
        onClick={() => this.props.showModal()}
      >
        <div
          className="panel-body"
          style={{
            padding: "6px 15px",
          }}
        >
          <div className="row">
            <div className="col-xs-2">
              <div className="col-xs-12" style={{ textAlign: "center" }}>
                <b>{pedido.id_pedido}</b>
              </div>
            </div>
            <div
              className="col-xs-2"
              style={{
                borderLeft: `solid 1.5px  ${
                  pedido.status_pedido === "PENDENTE"
                    ? "orange"
                    : pedido.status_pedido === "ENTREGUE"
                    ? "green"
                    : pedido.status_pedido === "CANCELADO"
                    ? "#8c2626"
                    : "#e3e3e3"
                }`,
              }}
            >
              <div
                className="col-xs-12"
                style={{ textAlign: "center", padding: "0px" }}
              >
                {pedido.nome_cliente.length > 10
                  ? pedido.nome_cliente.substring(0, 10) + "..."
                  : pedido.nome_cliente}
              </div>
            </div>
            {this.renderSwitch(pedido.status_pedido)}
            <div
              className="col-xs-2"
              style={{
                textAlign: "center",
                borderLeft: `solid 1px ${
                  pedido.status_pedido === "PENDENTE"
                    ? "orange"
                    : pedido.status_pedido === "ENTREGUE"
                    ? "green"
                    : pedido.status_pedido === "CANCELADO"
                    ? "#8c2626"
                    : "#e3e3e3"
                }`,
              }}
            >
              <div
                className="col-xs-12"
                style={{ textAlign: "center", padding: "0px" }}
              >
                {new Date(pedido.data_hora).toLocaleDateString("pt-BR")}
              </div>
            </div>
            <div
              className="col-xs-2"
              style={{
                borderLeft: `solid 1.5px ${
                  pedido.status_pedido === "PENDENTE"
                    ? "orange"
                    : pedido.status_pedido === "ENTREGUE"
                    ? "green"
                    : pedido.status_pedido === "CANCELADO"
                    ? "#8c2626"
                    : "#e3e3e3"
                }`,
                padding: "0px",
              }}
            >
              <div
                className="col-xs-12"
                style={{ padding: "0px", textAlign: "center" }}
              >
                {pedido.forma_pagamento}
              </div>
            </div>
            <div
              className="col-xs-2"
              style={{
                borderLeft: `solid 1.5px ${
                  pedido.status_pedido === "PENDENTE"
                    ? "orange"
                    : pedido.status_pedido === "ENTREGUE"
                    ? "green"
                    : pedido.status_pedido === "CANCELADO"
                    ? "#8c2626"
                    : "#e3e3e3"
                }`,
              }}
            >
              <div className="col-xs-12" style={{ textAlign: "center" }}>
                <b>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(pedido.vr_pedido)}
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PedidoRelatorio;
