import React, { Component } from "react";
import Perfil from "../Perfil/Perfil";
import Empresas from "../Empresas/Empresas";

class Config extends Component {
  state = {
    activeTab: "perfil",
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  renderTabContent = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case "perfil":
        return <Perfil />;
      case "empresas":
        return <Empresas />;
      case "planos":
        return <div>Planos Content</div>;
      case "ajuda":
        return <div>Ajuda Content</div>;
      default:
        return null;
    }
  };

  renderTab = (tab, label) => {
    const { activeTab } = this.state;
    return (
      <li role="presentation" className={activeTab === tab ? "active" : ""}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            this.setActiveTab(tab);
          }}
        >
          {label}
        </a>
      </li>
    );
  };

  render() {
    return (
      <div className="container-fluid container">
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <div className="title-page">
            <h2 className="text-center" style={{ color: "#FF5722" }}>
              Configurações
            </h2>
          </div>
          <ul className="nav nav-tabs">
            {this.renderTab("perfil", "Perfil")}
            {this.renderTab("empresas", "Empresas")}
            {this.renderTab("planos", "Planos")}
            {this.renderTab("ajuda", "Ajuda")}
          </ul>
          <div>{this.renderTabContent()}</div>
        </div>
      </div>
    );
  }
}

export default Config;
