import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ImageInput from "../../components/Imagens/ImageInput";
import * as PrestAPI from "../../service/api/PrestAPI";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "./Style.css";
import CatComponent from "./Components/CatComponente";
import AgendamentoProduto from "./AgendamentoProduto";

class ProdutoForm extends Component {
  static propTypes = {
    produto: PropTypes.object.isRequired,
    saveProduto: PropTypes.func.isRequired,
    isProdutoNew: PropTypes.bool.isRequired,
    redirectAdd: PropTypes.func,
    selectedCategoria: PropTypes.string,
  };

  state = {
    complementos: [],
    grupos: [],
    grupo: {},
    id_grupo: 0,
    tabIndex: 0,
    modo: "",
    inicio_oferta: "",
    fim_oferta: "",
    valor_oferta: 0,
    selectedComp: {},
    isCompSelected: false,
    imageUrl: null,
    imagem: null,
    status_produto: this.props.produto.status_produto || 0,
    id_produto: this.props.produto.id_produto || "",
    codigo_controle: this.props.produto.codigo_controle || "",
    nome: this.props.produto.nome || "",
    vr_unitario: this.props.produto.vr_unitario || "",
    tag: this.props.produto.tag || "",
    descricao: this.props.produto.descricao || "",
    errors: {},
  };

  componentDidMount() {
    const produto = this.props.produto;

    PrestAPI.get(
      "ProdutoGrupo",
      `estab=${produto.id_estabelecimento}&modo=retaguarda`
    ).then((value) => {
      this.setState({ grupos: value.categorias });

      if (produto) {
        this.defineGrupo(value.categorias);
      }
    });

    this.setState({ id_grupo: produto.id_grupo });
    this.setState({ complementos: produto.complementos });
    this.getCategoriaComplemento(
      produto.id_produto,
      produto.id_estabelecimento
    );

    if (produto.inicio_oferta && produto.fim_oferta !== "") {
      let inicio_oferta = new Date(produto.inicio_oferta)
        .toISOString()
        .substring(0, 10);
      let fim_oferta = new Date(produto.fim_oferta)
        .toISOString()
        .substring(0, 10);
      let valor_oferta = produto.valor_oferta;

      this.setState({ inicio_oferta, fim_oferta, valor_oferta });
    }
  }

  defineGrupo = (grupos) => {
    let produto = this.props.produto;
    let grupo = grupos.find((gp) => gp.id_grupo === produto.id_grupo);
    if (grupo) {
      this.setState({ grupo });
    }
  };

  getCategoriaComplemento(idProduto, idEstabelecimento) {
    PrestAPI.get(
      "ProdutoComplemento",
      `estab=${idEstabelecimento}&idProduto=${idProduto}&modo=retaguarda`
    ).then((value) => {
      this.setState({ complementos: value.complementos });
    });
  }

  uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("file", image);

      const result = await PrestAPI.add("upload-image", formData);

      if (result.ok) {
        const data = await result.json();
        return data.imageUrl;
      } else {
        const errorResponse = await result.json();
        console.log(errorResponse.error || "Erro ao carregar imagem");
        return null;
      }
    } catch (error) {
      console.log(error.message || "Erro ao adicionar imagem");
      return null;
    }
  };

  deleteCatComplemento = (idEstab, idProd, idCat) => {
    PrestAPI.del(
      "ProdutoComplemento",
      `?estab=${idEstab}&idProduto=${idProd}&idCatComplemento=${idCat}`
    ).then((res) => {
      if (res.status === 200) {
        this.getCategoriaComplemento(idProd, idEstab);
      } else {
        console.log("ERRO", res.status);
      }
    });
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState({
      [name]: type === "checkbox" ? checked : value,
    });
  };

  handleImageChange = (url) => {
    this.setState({ imagem: url });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let modo = this.state.modo;
    const {
      status_produto,
      id_grupo,
      nome,
      vr_unitario,
      codigo_controle,
      descricao,
      tag,
    } = this.state;

    let errors = {};

    if (!nome) {
      errors.nome = "O nome do produto é obrigatório.";
    }

    if (!vr_unitario || isNaN(parseFloat(vr_unitario))) {
      errors.vr_unitario =
        "O valor unitário é obrigatório e deve ser um número.";
    }

    if (!id_grupo || id_grupo === 0) {
      errors.id_grupo = "Você deve selecionar um grupo.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const { id_produto, id_estabelecimento } = this.props.produto;
    const grupoSelecionado =
      parseInt(id_grupo) ||
      (this.state.grupos[0] && this.state.grupos[0].id_grupo) ||
      null;

    const data = {
      id_produto,
      id_estabelecimento,
      id_grupo: grupoSelecionado,
      nome,
      vr_unitario: parseFloat(vr_unitario),
      codigo_controle,
      descricao,
      tag,
      status_produto: status_produto ? 1 : 0,
    };

    if (isNaN(data.vr_unitario) || data.vr_unitario === undefined) {
      data.vr_unitario = 0.0;
    }

    if (this.state.imagem) {
      const imageUrl = await this.uploadImage(this.state.imagem);
      if (imageUrl) {
        data.imagem = imageUrl;
      }
    } else if (this.props.produto.imagem !== "" && !this.state.imagem) {
      data.imagem = this.props.produto.imagem;
    }

    if (modo !== "oferta") {
      this.props.saveProduto(id_produto, data);
    }

    if (modo === "oferta") {
      const { inicio_oferta, fim_oferta, valor_oferta } = this.state;
      const oferta = {
        inicio_oferta: inicio_oferta,
        fim_oferta: fim_oferta,
        valor_oferta: parseFloat(valor_oferta),
      };

      this.props.saveProduto(id_produto, oferta, modo);
    }
  };

  selectComp = (complemento) => {
    this.setState({ selectedComp: complemento, isCompSelected: true });
  };

  componentBack = () => {
    this.setState({ selectedComp: {}, isCompSelected: false });
  };

  render() {
    const { produto, isProdutoNew } = this.props;
    const {
      complementos,
      grupos,
      tabIndex,
      inicio_oferta,
      fim_oferta,
      valor_oferta,
      isCompSelected,
      selectedComp,
      status_produto,
      id_produto,
      codigo_controle,
      nome,
      vr_unitario,
      tag,
      descricao,
    } = this.state;

    return (
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => this.setState({ tabIndex })}
      >
        {!isCompSelected ? (
          <>
            <TabList className="container">
              <Tab>{isProdutoNew ? <>Cadastro</> : <>Editar</>}</Tab>
              <Tab>Complemento</Tab>
              <Tab>Oferta</Tab>
              <Tab>Disponibilidade (Dias da semana)</Tab>
            </TabList>
            <TabPanel>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="col-md-6" style={{ marginBottom: "16px" }}>
                    <h3>Produto - Cadastro</h3>
                  </div>
                  <div className="col-md-6" style={{ marginBottom: "16px" }}>
                    <button
                      className="btn btn-vk btn-md btn-block"
                      onClick={() => this.props.redirectAdd()}
                    >
                      Novo Produto
                    </button>
                  </div>
                  <div className="col-md-12">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row" style={{ paddingRight: "60px" }}>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="form-group col-sm-3">
                              <label htmlFor="status">Status:</label>
                              <div
                                className="col-sm-12"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="checkbox"
                                  name="status_produto"
                                  checked={status_produto}
                                  id="status"
                                  onChange={this.handleChange}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    marginTop: "1px",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group col-sm-3">
                              <label>Id.Produto:</label>
                              <input
                                type="text"
                                name="id_produto"
                                value={id_produto}
                                className="form-control input-sm"
                                placeholder=""
                                disabled
                              />
                            </div>
                            <div className="form-group col-sm-5">
                              <label>Código Controle:</label>
                              <input
                                type="text"
                                name="codigo_controle"
                                value={codigo_controle}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-sm-8">
                              <label>Nome:</label>
                              <input
                                type="text"
                                name="nome"
                                value={nome}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                                required
                              />
                              {this.state.errors.nome && (
                                <div className="text-danger">
                                  {this.state.errors.nome}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-sm-3">
                              <label>Vr.Unitário:</label>
                              <input
                                type="number"
                                step="0.01"
                                name="vr_unitario"
                                value={vr_unitario}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                                required
                              />
                              {this.state.errors.vr_unitario && (
                                <div className="text-danger">
                                  {this.state.errors.vr_unitario}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-sm-6">
                              <label>Grupo:</label>
                              <select
                                name="id_grupo"
                                className="form-control input-sm"
                                id="select_grupo"
                                value={this.state.id_grupo}
                                onChange={this.handleChange}
                              >
                                {grupos.map((grupo) => (
                                  <option
                                    key={grupo.id_grupo}
                                    value={grupo.id_grupo}
                                  >
                                    {grupo.descricao
                                      ? grupo.descricao
                                      : "Grupo não definido"}
                                  </option>
                                ))}
                              </select>
                              {this.state.errors.id_grupo && (
                                <div className="text-danger">
                                  {this.state.errors.id_grupo}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-sm-5">
                              <label>Tag:</label>
                              <input
                                type="text"
                                name="tag"
                                value={tag}
                                className="form-control input-sm"
                                placeholder=""
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group col-sm-4">
                            <label>Imagem:</label>
                            <ImageInput
                              className="imagem-input"
                              name="imagem"
                              defaultValue={produto.imagem}
                              onImageChange={this.handleImageChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <label>Descriçao:</label>
                          <textarea
                            name="descricao"
                            value={descricao}
                            className="form-control input-sm"
                            placeholder=""
                            onChange={this.handleChange}
                            style={{
                              width: "100%",
                              maxHeight: "150px",
                              minHeight: "40px",
                              maxWidth: "100%",
                              minWidth: "100%",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-3">
                          <Link
                            to="/produto"
                            className="btn btn-default btn-lg btn-block"
                          >
                            Voltar
                          </Link>
                        </div>
                        <div className="form-group col-sm-9">
                          <button
                            className="btn btn-vk btn-lg btn-block"
                            onClick={() => this.setState({ modo: "" })}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="container-fluid">
                <CatComponent
                  idProduto={produto.id_produto}
                  idEstabelecimento={produto.id_estabelecimento}
                  complementos={complementos}
                  getProdutoComplemento={() => {
                    this.getCategoriaComplemento(
                      produto.id_produto,
                      produto.id_estabelecimento
                    );
                  }}
                  deleteCatComplemento={this.deleteCatComplemento}
                  selectComp={(complemento) => this.selectComp(complemento)}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <h3>Produto - Oferta</h3>
                  <p />
                  <h3>Cadastrar nova oferta:</h3>
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>Data Inicio:</label>
                        <input
                          type="date"
                          name="inicio_oferta"
                          value={inicio_oferta}
                          className="form-control input-lg"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label>Data Fim:</label>
                        <input
                          type="date"
                          name="fim_oferta"
                          value={fim_oferta}
                          className="form-control input-lg"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group col-lg-12">
                        <label>Valor da Oferta R$:</label>
                        <input
                          type="number"
                          step="0.01"
                          name="valor_oferta"
                          value={valor_oferta}
                          className="form-control input-lg"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <Link
                          to="/produto"
                          className="btn btn-default btn-lg btn-block"
                        >
                          Voltar
                        </Link>
                      </div>
                      <div className="form-group col-sm-9">
                        <button
                          className="btn btn-vk btn-lg btn-block"
                          onClick={() => {
                            this.setState({ modo: "oferta" });
                          }}
                        >
                          Salvar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-3"></div>
              </div>
            </TabPanel>
            <TabPanel>
              <AgendamentoProduto produto={produto} tipo={"produto"} />
            </TabPanel>
          </>
        ) : (
          <div className="container-fluid">
            <AgendamentoProduto
              produto={produto}
              tipo={"complemento"}
              complemento={selectedComp}
              voltar={this.componentBack}
            />
          </div>
        )}
      </Tabs>
    );
  }
}

export default ProdutoForm;
