import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class ProdutoList extends Component {
    static propTypes = {
        produtos: PropTypes.array.isRequired,
        editProduto: PropTypes.func.isRequired,
        getAllProdutos: PropTypes.func.isRequired
    }

    componentDidMount() {
        this.props.getAllProdutos()
    }

    render() {
        const { produtos, editProduto } = this.props

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-9">
                        <h3>Produtos</h3>
                    </div>
                    <div className="col-sm-3">
                        <Link
                            to="/produto-add"
                            className="btn btn-primary btn-block"
                        >Add</Link>
                    </div>
                </div>
                <div className="row table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th className="col-xs-1">Id.Produto</th>
                                <th className="col-xs-9">Nome</th>
                                <th className="col-xs-1">Vr.Unitário</th>
                                <th className="col-xs-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {produtos.map((produto) => (
                                <tr key={produto.id_produto}>
                                    <td>
                                        {produto.id_produto}
                                    </td>
                                    <td>
                                        {produto.nome}
                                    </td>
                                    <td className="text-right">
                                        {new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        }).format(produto.vr_unitario)}
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-primary btn-block"
                                            onClick={() => editProduto(produto)}
                                        >Editar</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ProdutoList