import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import serializeForm from "form-serialize";
import * as prestAPI from "../../../service/api/PrestAPI";

class ModalAgenda extends Component {
  static propTypes = {
    produto: PropTypes.object.isRequired,
    hideModal: PropTypes.func,
    modo: PropTypes.string.isRequired,
    setAgenda: PropTypes.func.isRequired,
    item: PropTypes.object,
    isNovaAgenda: PropTypes.bool,
    nome: PropTypes.string,
    complemento: PropTypes.object,
  };

  state = {
    disponibilidade: [
      {
        turno: 1,
        horario: "00:00|00:00",
      },
    ],
    dias: "",
    numeroR: 0,
  };

  handleSubmit = (e) => {
    let idProduto = this.props.produto.id_produto;
    let disponibilidade = this.state.disponibilidade;
    e.preventDefault();

    const form = e.target;
    const data = serializeForm(form, { hash: true });

    //Colocando hora de inicio/fim no horário do Array de disponibilidade
    if (disponibilidade.length > 1) {
      for (let i = 0; i < disponibilidade.length; i++) {
        if (disponibilidade[i] !== null || disponibilidade[i] !== undefined) {
          disponibilidade[i].horario = ""
            .concat(data.horaInicio[i], "|")
            .concat(data.horaFim[i]);

          this.setState({ disponibilidade });
        }
      }
    } else {
      disponibilidade[0].horario = ""
        .concat(data.horaInicio, "|")
        .concat(data.horaFim);

      this.setState({ disponibilidade });
    }

    //Array de dias para criação de string
    var dias = [];

    if (data.seg !== undefined && data.seg === "on") {
      dias.push("seg|");
    }
    if (data.ter !== undefined && data.ter === "on") {
      dias.push("ter|");
    }
    if (data.qua !== undefined && data.qua === "on") {
      dias.push("qua|");
    }
    if (data.qui !== undefined && data.qui === "on") {
      dias.push("qui|");
    }
    if (data.sex !== undefined && data.sex === "on") {
      dias.push("sex|");
    }
    if (data.sab !== undefined && data.sab === "on") {
      dias.push("sab|");
    }
    if (data.dom !== undefined && data.dom === "on") {
      dias.push("dom|");
    }

    //Retirando "|" do ultimo objeto do array e juntando tudo em 1 string
    if (dias.length === 0) {
      alert("Você precisa selecionar os dias da semana");
      return;
    } else {
      let ultimoDia = dias.pop().replace("|", "");
      dias.push(ultimoDia);
      dias = dias.join("");
    }

    let dados = {
      agenda: [
        {
          descricao: data.descricao,
          dias: dias,
          disponibilidade: disponibilidade,
        },
      ],
    };

    if (this.props.modo === "produto") {
      prestAPI
        .add(`Produto?modo=disponibilidade&idProduto=${idProduto}`, dados)
        .then((result) => {
          if (result.status === 200) {
            prestAPI
              .get(`Produto?id_produto=${idProduto}&modo=retaguarda`)
              .then((result) => {
                let agenda = result.produtos[0].agenda;
                this.props.setAgenda([]);
                this.props.setAgenda(agenda);
                alert("Salvo!");
                this.props.hideModal();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            alert("Erro ao salvar agenda!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (this.props.modo === "complemento") {
      prestAPI
        .add(
          `ProdutoComplementoItem?modo=disponibilidade&idComplemento=${this.props.complemento.id_complemento}`,
          dados
        )
        .then((result) => {
          if (result.status === 200) {
            prestAPI
              .get(
                "ProdutoComplemento",
                `estab=${this.props.produto.id_estabelecimento}&idProduto=${this.props.produto.id_produto}&modo=retaguarda`
              )
              .then((res) => {
                let arrComp = [];
                let preparaFiltro = res.complementos;
                // eslint-disable-next-line
                for (let [_, cat] of preparaFiltro.entries()) {
                  if (
                    cat.id_categoria_complemento ===
                    this.props.complemento.id_categoria_complemento
                  ) {
                    for (let i = 0; i < cat.itens.length; i++) {
                      if (
                        cat.itens[i].id_complemento ===
                        this.props.complemento.id_complemento
                      ) {
                        arrComp.push(cat.itens[i]);
                      }
                    }
                  }
                }

                let agenda = arrComp[0].agenda;
                this.props.setAgenda([]);
                this.props.setAgenda(agenda);
                alert("Salvo!");
                this.props.hideModal();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleAdd = () => {
    let disponibilidade = this.state.disponibilidade;

    let numeroTurno = disponibilidade.length + 1;
    let disp = {
      turno: numeroTurno,
      horario: "00:00|00:00",
    };

    disponibilidade.push(disp);
    this.setState({ disponibilidade });
  };

  handleRemove = (index) => {
    let disponibilidade = this.state.disponibilidade;

    disponibilidade.splice(index, 1);

    this.setState({ disponibilidade });
  };

  componentDidMount() {
    if (!this.props.isNovaAgenda) {
      let itDisp = this.props.item.disponibilidade;
      let dias = this.props.item.dias;
      if (this.props.modo === "produto") {
        let numeroR = Math.floor(Math.random() * 1001);

        for (let i = 0; i < itDisp.length; i++) {
          let horas = itDisp[i].horario.split("|");
          itDisp[i].horaInicio = horas[0];
          itDisp[i].horaFim = horas[1];

          this.setState({ disponibilidade: itDisp, numeroR });
        }
      }
      if (this.props.modo === "complemento") {
        let numeroR = Math.floor(Math.random() * 1001);

        for (let i = 0; i < itDisp.length; i++) {
          let horas = itDisp[i].horario.split("|");
          itDisp[i].horaInicio = horas[0];
          itDisp[i].horaFim = horas[1];

          this.setState({ disponibilidade: itDisp, numeroR });
        }
      }

      this.verificaDias(dias);
      this.setState({ disponibilidade: itDisp });
    }
  }

  verificaDias = (dias) => {
    let numero = this.state.numeroR;
    let dom = document.querySelector(`#domR${numero}`);
    let domChk = document.querySelector(`#domChk${numero}`);
    let seg = document.querySelector(`#segR${numero}`);
    let segChk = document.querySelector(`#segChk${numero}`);
    let ter = document.querySelector(`#terR${numero}`);
    let terChk = document.querySelector(`#terChk${numero}`);
    let qua = document.querySelector(`#quaR${numero}`);
    let quaChk = document.querySelector(`#quaChk${numero}`);
    let qui = document.querySelector(`#quiR${numero}`);
    let quiChk = document.querySelector(`#quiChk${numero}`);
    let sex = document.querySelector(`#sexR${numero}`);
    let sexChk = document.querySelector(`#sexChk${numero}`);
    let sab = document.querySelector(`#sabR${numero}`);
    let sabChk = document.querySelector(`#sabChk${numero}`);

    if (dias.includes("dom")) {
      dom.classList.add("active");
      domChk.checked = true;
    }
    if (dias.includes("seg")) {
      seg.classList.add("active");
      segChk.checked = true;
    }
    if (dias.includes("ter")) {
      ter.classList.add("active");
      terChk.checked = true;
    }
    if (dias.includes("qua")) {
      qua.classList.add("active");
      quaChk.checked = true;
    }
    if (dias.includes("qui")) {
      qui.classList.add("active");
      quiChk.checked = true;
    }
    if (dias.includes("sex")) {
      sex.classList.add("active");
      sexChk.checked = true;
    }
    if (dias.includes("sab")) {
      sab.classList.add("active");
      sabChk.checked = true;
    }
  };

  render() {
    const { disponibilidade, numeroR } = this.state;
    const { item, isNovaAgenda, modo } = this.props;
    return (
      <div
        className="complement-list"
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          minHeight: "550px",
          maxHeight: "550px",
        }}
      >
        <form onSubmit={this.handleSubmit}>
          <div className="col-sm-12 modal-complement-title">
            <b>
              {modo === "produto"
                ? `Nova Agenda (produto)`
                : modo === "complemento"
                ? `Nova Agenda (complemento)`
                : null}
            </b>
          </div>
          <div className="form-group col-sm-12">
            <div className="form-group col-sm-12">
              <label htmlFor="descricao" style={{ marginTop: "10px" }}>
                Descrição:
              </label>
              <input
                type="text"
                name="descricao"
                defaultValue={isNovaAgenda ? "" : item.descricao}
                className="form-control input-md"
                placeholder=""
                required
                readOnly={!isNovaAgenda}
              />
            </div>
            <div className="form-group col-sm-6">
              <label style={{ marginTop: "10px" }}>Dias da semana:</label>
              <div className="w-100"></div>
              <div className="btn-group" data-toggle="buttons">
                <label className="btn btnExc btn-md" id={`domR${numeroR}`}>
                  <input type="checkbox" name="dom" id={`domChk${numeroR}`} />D
                </label>
                <label className="btn btnExc btn-md" id={`segR${numeroR}`}>
                  <input type="checkbox" name="seg" id={`segChk${numeroR}`} />S
                </label>
                <label className="btn btnExc btn-md" id={`terR${numeroR}`}>
                  <input type="checkbox" name="ter" id={`terChk${numeroR}`} />T
                </label>
                <label className="btn btnExc btn-md" id={`quaR${numeroR}`}>
                  <input type="checkbox" name="qua" id={`quaChk${numeroR}`} />Q
                </label>
                <label className="btn btnExc btn-md" id={`quiR${numeroR}`}>
                  <input type="checkbox" name="qui" id={`quiChk${numeroR}`} />Q
                </label>
                <label className="btn btnExc btn-md" id={`sexR${numeroR}`}>
                  <input type="checkbox" name="sex" id={`sexChk${numeroR}`} />S
                </label>
                <label className="btn btnExc btn-md" id={`sabR${numeroR}`}>
                  <input type="checkbox" name="sab" id={`sabChk${numeroR}`} />S
                </label>
              </div>
            </div>
            <div className="form-group col-sm-6">
              <div
                className="btn btn-md btn-vk btn-block"
                type="button"
                style={{ marginTop: "33px" }}
                onClick={this.handleAdd}
              >
                Adicionar turno
              </div>
            </div>
            <hr />
          </div>
          {disponibilidade.map((turno, index) => (
            <div className="container-fluid" key={index}>
              <div className="row">
                <div className="col-sm-6 modal-complement-title">
                  <b> {turno.turno}° turno </b>
                </div>
                <div className="col-sm-6" style={{ textAlign: "center" }}>
                  <button
                    className="btnExc"
                    type="button"
                    disabled={
                      turno.turno === 1 ||
                      turno !== disponibilidade[disponibilidade.length - 1]
                    }
                    onClick={() => this.handleRemove(index)}
                  >
                    <span
                      className="glyphicon glyphicon-trash"
                      aria-hidden="true"
                      style={{ marginTop: "2px" }}
                    ></span>
                  </button>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="horaInicio" style={{ marginTop: "10px" }}>
                      Hora Início:
                    </label>
                    <input
                      type="time"
                      className="form-control input-md"
                      name="horaInicio"
                      defaultValue={turno.horaInicio}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="horaFim" style={{ marginTop: "10px" }}>
                      Hora Fim:
                    </label>
                    <input
                      type="time"
                      className="form-control input-md"
                      name="horaFim"
                      required
                      defaultValue={turno.horaFim}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
          <div className="w-100"></div>
          <div className="form-group col-sm-6">
            <button className="form-control input-sm btn-vk">Salvar</button>
          </div>
        </form>
        <div className="form-group col-sm-6">
          <button
            className="form-control input-sm btn-secondary"
            onClick={() => this.props.hideModal()}
          >
            Fechar
          </button>
        </div>
      </div>
    );
  }
}

export default ModalAgenda;
