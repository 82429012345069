import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Header.css";

class Header extends Component {
  static propTypes = {
    pedidosConfirmar: PropTypes.bool,
    estabelecimento: PropTypes.object,
    logout: PropTypes.func.isRequired,
    getPedidos: PropTypes.func.isRequired,
  };

  render() {
    const { pedidosConfirmar, estabelecimento, logout, getPedidos } =
      this.props;

    return (
      <nav className="navbar navbar-fixed-top" style={{ padding: "0 30px" }}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
            style={{ borderColor: "white" }}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" style={{ color: "white" }}></span>
            <span className="icon-bar" style={{ color: "white" }}></span>
            <span className="icon-bar" style={{ color: "white" }}></span>
          </button>
          <a
            className="navbar-brand"
            href="/"
            style={{ color: "#000", margin: "8px 0px", fontSize: "20px" }}
          >
            <b>{estabelecimento.razao_social}</b>
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav">
            {estabelecimento !== null ? (
              <Fragment>
                {/* <li>
                  <Link to="/produto" className="btn btn-default navbar-btn">
                    Produtos
                  </Link>
                </li> */}
                <li className="dropdown">
                  <a
                    className="dropdown-toggle btn navbar-btn"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Produtos<span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/produto">Produtos</Link>
                    </li>
                    <li>
                      <Link to="/produto_grupo-list">Categorias</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/taxa_entrega-list"
                    className="btn btn-default navbar-btn"
                  >
                    Taxas
                  </Link>
                </li>
                <li>
                  <Link to="/relatorios" className="btn btn-default navbar-btn">
                    Relatórios
                  </Link>
                </li>
                <li>
                  <a
                    onClick={() => getPedidos()}
                    className="btn btn-default navbar-btn"
                  >
                    {pedidosConfirmar === true ? (
                      <div style={{ color: "orange" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="orange"
                          className="bi bi-exclamation-triangle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <b>Pedidos</b>
                      </div>
                    ) : (
                      <div>Pedidos</div>
                    )}
                  </a>
                </li>
              </Fragment>
            ) : (
              <Fragment>
                <li></li>
                <li></li>
              </Fragment>
            )}
          </ul>
          <ul className="nav navbar-nav navbar-right">
            {estabelecimento.id_estabelecimento === 3 && (
              <li>
                <Link to="/Config" className="btn btn-default navbar-btn">
                  <span
                    className="glyphicon glyphicon-cog"
                    aria-hidden="true"
                  ></span>
                  <span className="text-center">Config</span>
                </Link>
              </li>
            )}
            {estabelecimento !== null ? (
              <li>
                <a
                  onClick={() => logout()}
                  className="btn btn-default navbar-btn"
                >
                  <span
                    className="glyphicon glyphicon-log-out"
                    aria-hidden="true"
                  ></span>

                  <span>Sair</span>
                </a>
              </li>
            ) : (
              <li></li>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header;
