import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BrowserTracing } from "@sentry/tracing";
import "./App.css";

import * as PrestAPI from "./service/api/PrestAPI";

import Header from "./components/Header/Header";
import ProdutoList from "./pages/Produto/ProdutoList";
import Produto from "./pages/Produto/Produto";
import ProdutoForm from "./pages/Produto/ProdutoForm";
import Login from "./pages/Login/Login";
import Pedidos from "./pages/Pedidos/Pedidos";
import ProdutoGrupoList from "./pages/ProdutoGrupo/ProdutoGrupoList";
import ProdutoGrupoForm from "./pages/ProdutoGrupo/ProdutoGrupoForm";
import TaxaEntregaList from "./pages/TaxaEntrega/TaxaEntregaList";
import TaxaEntregaForm from "./pages/TaxaEntrega/TaxaEntregaForm";
import * as dotenv from "dotenv";
import som from "./assets/audios/audio.mp3";
import * as Sentry from "@sentry/react";
import Relatorios from "./pages/Relatorios/Relatorios";
import Config from "./pages/Config/Config";
import EstabelecimentoContext from "./context/EstabelecimentoContext";
import Parceiro from "./pages/Parceiro/Parceiro";

const audio = new Audio(som);
dotenv.config();

var CryptoJS = require("crypto-js");

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}
class App extends Component {
  state = {
    estabelecimento: null,
    buscando: false,
    produto: {},
    produtos: [],
    grupo: {},
    taxa: {},
    taxas: [],
    isNewTaxa: true,
    pedidos: [],
    imagem: "",
    pedidosConfirmar: false,
    isProdutoNew: true,
    selectedCategoria: "",
  };
  static contextType = EstabelecimentoContext;

  buscaPed = setInterval(() => this.busca(), 30000);

  clearCacheData = async () => {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map((name) => caches.delete(name)));
    console.log("Cache cleared successfully");
  };
  componentDidMount = () => {
    const hostname = window.location.hostname;
    const mapping = {
      "painel.vempraka.com.br": {
        title: "Painel - VemPraKa",
        favicon: "https://biblioteca.mrstecno.com.br/vempraka/logo/favicon.ico",
      },
      "painel.donfrango.com.br": {
        title: "Painel - Don Frango",
        favicon:
          "https://biblioteca.mrstecno.com.br/vempraka/empresas/Don%20Frango.jpg",
      },
      "painel.mrsdelivery.vempraka.com.br": {
        title: "Painel - MRS Delivery",
        favicon:
          "https://img.freepik.com/vetores-premium/entregador-montando-a-ilustracao-de-scooter-vermelho_9845-14.jpg?w=740",
      },
    };

    const defaultConfig = {
      title: hostname,
      favicon: "https://biblioteca.mrstecno.com.br/vempraka/logo/favicon.ico",
    };

    const siteConfig = mapping[hostname] || defaultConfig;

    let favicon = document.querySelector("link[rel='shortcut icon']");
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.rel = "shortcut icon";
      document.head.appendChild(favicon);
    }
    favicon.href = siteConfig.favicon;

    document.title = siteConfig.title;

    this.setState({ imagem: siteConfig.favicon });

    if (this.state.estabelecimento) {
      this.loadPedidos();
    }

    this.clearCacheData().catch((error) =>
      console.error("Failed to clear cache data", error)
    );
  };

  loadPedidos = () => {
    const { estabelecimento } = this.state;
    if (estabelecimento) {
      PrestAPI.get("Pedidos", `estab=${estabelecimento.id_estabelecimento}`)
        .then((value) => this.setState({ pedidos: value.pedidos }))
        .catch((erro) => toast.error(erro));
    }
  };

  setNovaTaxa = () => {
    this.setState({ isNewTaxa: true });
  };

  getProdutoNovo = () => {
    const estabelecimento = this.state.estabelecimento;

    return {
      id_produto: null,
      id_estabelecimento: estabelecimento.id_estabelecimento,
      codigo_controle: null,
      nome: "",
      descricao: null,
      vr_unitario: 0,
    };
  };

  addProduto = (produto, history) => {
    PrestAPI.add("Produto", produto)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          const errorMessage =
            typeof data.error === "string"
              ? data.error.includes("duplicate key value")
                ? "Item já existe."
                : "Não foi possível salvar produto."
              : "Erro inesperado ao salvar produto.";

          return toast.error(errorMessage);
        }

        this.setState({ produto: data }, () => {
          toast.success("Salvo!");
          this.getAllProdutos();
          history.push("/produto-edit");
        });
      })
      .catch((error) => {
        const errorMessage = error.message
          ? error.message
          : "Erro desconhecido ao salvar produto.";
        toast.error(errorMessage);
      });
  };

  getAllProdutos = () => {
    const estabelecimento = this.state.estabelecimento;
    PrestAPI.get("Produto", `estab=${estabelecimento.id_estabelecimento}`).then(
      (result) => {
        this.setState({ produtos: result.produtos });
      }
    );
  };

  updateProduto = (idProduto, produto, Modo) => {
    PrestAPI.update("Produto", `?id_produto=${idProduto}&modo=${Modo}`, produto)
      .then((res) => {
        if (res.status === 200) {
          this.getProduto(idProduto);
          this.getAllProdutos();
          toast.success("Salvo!");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  getProdutoGrupoNovo = () => {
    const estabelecimento = this.state.estabelecimento;

    return {
      id_grupo: null,
      id_estabelecimento: estabelecimento.id_estabelecimento,
      descricao: "",
    };
  };

  addProdutoGrupo = (grupo, history) => {
    PrestAPI.add("ProdutoGrupo", grupo)
      .then((data) => {
        if (data.status === 200) {
          //Retornar Grupo
          this.setState({ grupo: data });
          history.push("/produto_grupo-edit");
        } else {
          toast.error(data.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  updateProdutoGrupo = (idGrupo, grupo) => {
    PrestAPI.update("ProdutoGrupo", `?id_grupo=${idGrupo}`, grupo)
      .then((data) => {
        toast.success("Salvo");
        this.setState({ grupo: data });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  getTaxaEntregaNovo = () => {
    const estabelecimento = this.state.estabelecimento;

    if (!estabelecimento || !estabelecimento.id_estabelecimento) {
      console.error("Estabelecimento inválido:", estabelecimento);
      toast.error("Estabelecimento inválido.");
      return;
    }
    return {
      id_taxa: null,
      id_estabelecimento: estabelecimento.id_estabelecimento,
      bairro: "",
      cep: "",
    };
  };

  addTaxaEntrega = (taxa, history) => {
    PrestAPI.add("Taxa", taxa)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((err) => {
            throw new Error(err.error || "Erro desconhecido");
          });
        }
      })
      .then((res) => {
        if (res.error) {
          toast.error(`Erro: ${res.error}`);
          return;
        }
        toast.success("Salvo!");
        this.setState({ taxa: res, isNewTaxa: false });
        history.push("/taxa_entrega-edit");
      })
      .catch((error) => {
        console.error("Erro ao adicionar taxa:", error);
        toast.error("Não foi possível cadastrar a taxa..\nTente novamente");
      });
  };

  updateTaxaEntrega = (idTaxa, taxa) => {
    PrestAPI.update("Taxa", `?id_taxa=${idTaxa}`, taxa)
      .then((res) => {
        toast.success("Salvo!");
        this.setState({
          taxa: res,
        });
      })
      .catch((error) => {
        toast.error("Não foi possível atualizar a taxa..\nTente novamente");
      });
  };

  logout = () => {
    this.setState({ estabelecimento: null });
    this.setState({ buscando: false });
    localStorage.removeItem("Estab");
    audio.pause();
    audio.currentTime = 0;
  };

  login = (data, history) => {
    PrestAPI.getToken("gatekeeper", data).then((result) => {
      if (result.status === "sucess") {
        this.getEstabelecimento(data, history);
      } else {
        toast.error("Usuário ou senha não conferem", {
          id: "erroLogin",
        });
        localStorage.clear();
        return;
      }
    });
  };

  getEstabelecimento = (data, history) => {
    const { cnpj, senha } = this.state;
    const { setEstabelecimento } = this.context;
    PrestAPI.get(
      "Estabelecimento",
      `cnpj=${data.cnpj}&senha=${data.senha}`
    ).then((result) => {
      if (result.length === 0) {
        toast.error("Estabelecimento não encontrado");
        localStorage.clear();
        return;
      }
      const dadosStorage = {
        uid: data.cnpj,
        ticket: data.senha,
      };

      const encryptDados = CryptoJS.AES.encrypt(
        JSON.stringify(dadosStorage),
        process.env.REACT_APP_ENCRYPT_PASS
      ).toString();

      localStorage.setItem("Estab", encryptDados);
      const estabelecimentoResult = result.estabelecimentos[0];
      setEstabelecimento(estabelecimentoResult);

      const estabelecimento = result.estabelecimentos[0];
      this.setState({ estabelecimento: {} });
      this.setState({ estabelecimento });
      this.setState({ buscando: true });

      this.loadPedidos();

      history.push("/produto");
    });
  };

  setConfirmaPedido = () => {
    this.setState({ pedidosConfirmar: true });
  };

  unsetConfirmaPedido = () => {
    this.setState({ pedidosConfirmar: false });
  };
  componentWillUnmount() {
    clearInterval(this.buscaPed);
  }

  busca = () => {
    const { buscando, estabelecimento } = this.state;
    if (!buscando || !estabelecimento) return;
    PrestAPI.get(
      "Pedidos",
      `estab=${this.state.estabelecimento.id_estabelecimento}`
    )
      .then((value) => {
        const hasPendingOrders = value.pedidos.some(
          (pedido) => pedido.status_pedido === "PENDENTE"
        );

        if (hasPendingOrders) {
          toast(`Você tem pedidos para confirmar!`, {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="orange"
                className="bi bi-exclamation-triangle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            ),
            id: "Pedidos",
          });
          audio.play();
          this.setConfirmaPedido();
          this.setState({ pedidos: value.pedidos });
          return;
        } else {
          this.unsetConfirmaPedido();
          this.setState({ pedidos: value.pedidos });
        }
      })
      .catch((erro) => {
        alert(erro);
      });
  };

  getProduto = (idProduto) => {
    PrestAPI.get("Produto", `id_produto=${idProduto}`).then((result) => {
      this.setState({ produto: result.produtos[0] });
    });
  };

  render() {
    const {
      estabelecimento,
      produto,
      produtos,
      grupo,
      taxa,
      isNewTaxa,
      pedidos,
      imagem,
      pedidosConfirmar,
      isProdutoNew,
      selectedCategoria,
    } = this.state;
    const hostname = window.location.hostname;

    if (
      hostname === "https://parceiros.vempraka.com.br" ||
      hostname === "https://parceiros.anoteja.com.br" ||
      window.location.href.includes("parceiros.vempraka.com.br") ||
      window.location.href.includes("parceiros.anoteja.com.br")
    ) {
      const parceiroName = window.location.href.includes("vempraka")
        ? "vempraka"
        : "anoteja";
      return (
        <Route
          exact
          path="/"
          render={({ match, history }) => <Parceiro name={parceiroName} />}
        />
      );
    }

    return (
      <div>
        <Route
          exact
          path="/parceiros/:parceiroName"
          render={({ match, history }) => <Parceiro name={match.params.parceiroName} />}
        />
        <Route
          exact
          path="/"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <Header
                pedidosConfirmar={pedidosConfirmar}
                estabelecimento={estabelecimento}
                logout={this.logout}
                getPedidos={() => {
                  history.push("/pedidos");
                }}
              />
            )
          }
        />
        <Route
          exact
          path="/login"
          render={({ history }) => (
            <>
              <Toaster />
              <Login
                login={(data) => {
                  this.login(data, history);
                }}
                imagem={imagem}
              />
              <style>{"body { background-color: #d9d9d9; }"}</style>
            </>
          )}
        />

        <Route
          exact
          path="/produto"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <Produto
                    estabelecimento={estabelecimento}
                    editProduto={(produto) => {
                      this.setState({ produto, isProdutoNew: false });
                      history.push("/produto-edit");
                    }}
                    editGrupo={(grupo) => {
                      this.setState({ grupo });
                      history.push("/produto_grupo-edit");
                    }}
                    redirectAdd={() => {
                      this.setState({ isProdutoNew: true });
                      history.push("/produto-add");
                    }}
                    selectedCategoria={selectedCategoria}
                    setSelectedCategoria={(option) => {
                      this.setState({ selectedCategoria: option });
                    }}
                  />
                </div>
              </>
            )
          }
        />

        <Route
          exact
          path="/config"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <Config />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/produto-list"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <ProdutoList
                    produtos={produtos}
                    getAllProdutos={() => {
                      this.getAllProdutos();
                    }}
                    editProduto={(produto) => {
                      this.setState({ produto });
                      history.push("/produto-edit");
                    }}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/produto-add"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <ProdutoForm
                    produto={this.getProdutoNovo()}
                    saveProduto={(idProduto, produto) => {
                      this.addProduto(produto, history);
                    }}
                    isProdutoNew={isProdutoNew}
                    selectedCategoria={selectedCategoria}
                    redirectAdd={() => {
                      history.push("/produto-add");
                    }}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/produto-edit"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <ProdutoForm
                    produto={produto}
                    saveProduto={(id, data, modo) =>
                      this.updateProduto(id, data, modo)
                    }
                    isProdutoNew={isProdutoNew}
                    redirectAdd={() => {
                      this.setState({ isProdutoNew: true });
                      history.push("/produto-add");
                    }}
                    selectedCategoria={selectedCategoria}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/produto_grupo-list"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <Toaster />
                  <ProdutoGrupoList
                    estabelecimento={estabelecimento}
                    editGrupo={(grupo) => {
                      this.setState({ grupo });
                      history.push("/produto_grupo-edit");
                    }}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/produto_grupo-add"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <Toaster />
                  <ProdutoGrupoForm
                    grupo={this.getProdutoGrupoNovo()}
                    saveGrupo={(idGrupo, grupo) => {
                      this.addProdutoGrupo(grupo, history);
                    }}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/produto_grupo-edit"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <Toaster />
                  <ProdutoGrupoForm
                    grupo={grupo}
                    saveGrupo={this.updateProdutoGrupo}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/pedidos"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <Pedidos
                    estabelecimento={estabelecimento}
                    pedidos={pedidos}
                    setConfirmaPedido={() => this.setConfirmaPedido()}
                    unsetConfirmaPedido={() => this.unsetConfirmaPedido()}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/taxa_entrega-list"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <TaxaEntregaList
                    estabelecimento={estabelecimento}
                    editTaxa={(taxa) => {
                      this.setState({ taxa, isNewTaxa: false });
                      history.push("/taxa_entrega-edit");
                    }}
                    setNovaTaxa={() => this.setNovaTaxa()}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/taxa_entrega-add"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <TaxaEntregaForm
                    taxa={this.getTaxaEntregaNovo()}
                    saveTaxa={(idTaxa, taxa) => {
                      this.addTaxaEntrega(taxa, history);
                    }}
                    isNewTaxa={isNewTaxa}
                    setNovaTaxa={() => this.setNovaTaxa()}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/taxa_entrega-edit"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <TaxaEntregaForm
                    taxa={taxa}
                    saveTaxa={this.updateTaxaEntrega}
                    isNewTaxa={isNewTaxa}
                    setNovaTaxa={() => this.setNovaTaxa()}
                  />
                </div>
              </>
            )
          }
        />
        <Route
          exact
          path="/relatorios"
          render={({ history }) =>
            estabelecimento === null ? (
              <Redirect to="/login" />
            ) : (
              <>
                <Toaster />
                <Header
                  pedidosConfirmar={pedidosConfirmar}
                  estabelecimento={estabelecimento}
                  logout={this.logout}
                  getPedidos={() => {
                    history.push("/pedidos");
                  }}
                />
                <div className="container-fluid" style={{ marginTop: "85px" }}>
                  <Toaster />
                  <Relatorios estabelecimento={estabelecimento} />
                </div>
              </>
            )
          }
        />
      </div>
    );
  }
}

export default App;
