import React, { Component } from "react";
import PropTypes from "prop-types";
import serializeForm from "form-serialize";
import * as PrestAPI from "../../../service/api/PrestAPI";

class NewCategoriaComplement extends Component {
  static propTypes = {
    idEstabelecimento: PropTypes.number,
    idProduto: PropTypes.number,
    ocultaCategoria: PropTypes.bool.isRequired,
    getCatComplementos: PropTypes.func,
    ocultaCategoriaComponent: PropTypes.func,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = serializeForm(form, { hash: true });
    let status_categoria = document.getElementById("status_categoria_form");

    if (data.nome_categoria !== "") {
      if (data.qtde_maxima > 1) {
        data.modo_multiplo = 1;
      } else {
        data.modo_multiplo = 0;
      }

      if (data.qtde_min >= 1) {
        data.uso_obrigatorio = 1;
      } else {
        data.uso_obrigatorio = 0;
      }

      //!!! Modo de cobrança vem aqui

      // if (data.modo_cobranca === "on") {
      //   data.modo_cobranca = 1;
      // } else {
      //   data.modo_cobranca = 0;
      // }

      // if (data.modo_multiplo === "on") {
      //   data.modo_multiplo = 1;
      // } else {
      //   data.modo_multiplo = 0;
      // }

      // if (data.uso_obrigatorio === "on") {
      //   data.uso_obrigatorio = 1;
      // } else {
      //   data.uso_obrigatorio = 0;
      // }

      //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      data.id_estabelecimento = Number.parseInt(data.id_estabelecimento, 10);
      data.id_produto = Number.parseInt(data.id_produto, 10);
      data.qtde_maxima = Number.parseInt(data.qtde_maxima, 10);
      data.qtde_min = Number.parseInt(data.qtde_min, 10);
      data.id_estabelecimento = this.props.idEstabelecimento;
      data.id_produto = this.props.idProduto;
      if (status_categoria.checked) {
        data.status_categoria = 1;
      } else {
        data.status_categoria = 0;
      }
      this.saveCategoriaComplemento(data);
      this.props.ocultaCategoriaComponent();
    } else {
      alert("Informe os dados necessários");
    }
  };

  saveCategoriaComplemento(complemento) {
    PrestAPI.add("ProdutoComplemento", complemento)
      .then((data) => {
        if (data.error === undefined) {
          if (this.props.getCatComplementos) {
            this.props.getCatComplementos(data);

            this.setState({ isShow: false });
          }
        } else {
          alert(data.error);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  render() {
    const { ocultaCategoria } = this.props;

    return (
      <div>
        {ocultaCategoria ? null : (
          <div className="cat-complement-geral">
            <br />
            <form onSubmit={this.handleSubmit}>
              <div className="form-group col-sm-4">
                <label>Nome grupo</label>
                <input
                  type="text"
                  name="nome_categoria"
                  className="form-control input-sm"
                  placeholder=""
                />
              </div>
              <div className="form-group col-sm-3">
                <label>Quantidade mínima</label>
                <input
                  type="number"
                  name="qtde_min"
                  min="0"
                  className="form-control input-sm"
                  placeholder=""
                />
              </div>
              <div className="form-group col-sm-3">
                <label>Quantidade máxima</label>
                <input
                  type="number"
                  name="qtde_maxima"
                  className="form-control input-sm"
                  placeholder=""
                />
              </div>
              <div className="form-group col-sm-2">
                <div className="col-sm-12">
                  <label htmlFor="status">Status:</label>
                </div>
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    name="status_categoria"
                    defaultChecked={true}
                    id="status_categoria_form"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "1px",
                    }}
                  />
                </div>
              </div>
              <div className="form-group col-sm-7">
                <label>Descrição</label>
                <input
                  type="text"
                  name="descricao_categoria"
                  className="form-control input-sm"
                  placeholder=""
                />
              </div>
              {/* <div className="col-sm-5">
                <input
                  type="checkbox"
                  name="uso_obrigatorio"
                  style={{ alignItems: "center" }}
                />{" "}
                Uso obrigatório{" "}
                <input
                  type="checkbox"
                  name="modo_multiplo"
                  style={{ alignItems: "center" }}
                />{" "}
                Multiplo{" "}
                <input
                  type="checkbox"
                  name="modo_cobranca"
                  style={{ alignItems: "center" }}
                />{" "}
                Cobrar pelo mais caro
              </div> */}
              <div
                className="form-group col-sm-3"
                style={{ paddingTop: "4px" }}
              >
                <label></label>
                <button className="form-control input-sm btn-vk">
                  Salvar
                </button>
              </div>

              <br />
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default NewCategoriaComplement;
