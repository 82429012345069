export const validateFields = (estabelecimento) => {
  const { razao_social, cnpj, email } = estabelecimento;
  const errors = {};

  if (!razao_social) errors.razao_social = "Razão social é obrigatória.";
  if (!cnpj) errors.cnpj = "CNPJ é obrigatório.";
  if (!email) errors.email = "Email é obrigatório.";

  const cnpjPattern = /^\d{14}$/;
  if (!cnpjPattern.test(cnpj.replace(/\D/g, ""))) {
    errors.cnpj = "CNPJ inválido.";
  }

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    errors.email = "Email inválido.";
  }

  return errors;
};

export const validateImage = (image) => {
  const validTypes = ["image/jpeg", "image/png", "image/gif"];
  const maxSize = 2 * 1024 * 1024; // 2MB

  if (!validTypes.includes(image.type)) {
    return "Formato de imagem inválido. Permitido apenas JPEG, PNG, ou GIF.";
  }

  if (image.size > maxSize) {
    return "O tamanho da imagem não pode exceder 2MB.";
  }

  return null;
};
