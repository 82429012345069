import React, { Component } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import "./Relatorios.css";
import search from "../../assets/icons/no-results.png";
import PedidoRelatorio from "./PedidoRelatorio";
import { toast } from "react-hot-toast";
import ModalRelatorios from "react-modal";
import ModalRelatorio from "./ModalRelatorio";
import * as PrestAPI from "../../service/api/PrestAPI";

class Relatorios extends Component {
  static propTypes = {
    estabelecimento: PropTypes.object.isRequired,
  };

  state = {
    tabIndex: 0,
    filtrando: false,
    loading: false,
    relatorio_pedidos: [],
    pedidos: [],
    pedido: {},
    itens: [],
    subtotal: 0,
    id: 0,
    status: "",
    forma: "",
    inicio: "",
    fim: "",
    ordem: "Menor - Maior",
    totalPedidos: 0,
    isModalVisible: false,
    mediaPedidos: 0,
    mediaValor: 0.0,
    mediaPedCheck: true,
    mediaVrCheck: true,
  };

  componentDidMount = () => {
    PrestAPI.get(
      "relatorio",
      `estab=${this.props.estabelecimento.id_estabelecimento}`
    ).then((data) => {
      this.setState({ relatorio_pedidos: data });
    });
    this.setState({ filtrando: false });
  };

  showModal = (pedido) => {
    PrestAPI.get("Pedidos", `id_pedido=${pedido.id_pedido}`).then((value) => {
      this.setState({ pedido });
      this.setState({ itens: value });
      this.calSubtotal();
    });
    this.setState({ isModalVisible: true });
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

  calSubtotal = () => {
    let itens = this.state.itens;
    let arrPreco = [];
    // eslint-disable-next-line
    for (let [_, item] of itens.entries()) {
      if (
        item.vr_total_sub_itens !== null &&
        item.vr_total_sub_itens !== undefined
      ) {
        let preco =
          item.vr_unitario + item.vr_total_sub_itens * item.quantidade;

        arrPreco.push(preco);
      } else {
        let preco = item.vr_unitario * item.quantidade;

        arrPreco.push(preco);
      }
    }

    let subtotal = arrPreco.reduce((soma, i) => {
      return soma + i;
    });

    this.setState({ subtotal });
  };

  setFiltro = () => {
    let pedidosProp = this.state.relatorio_pedidos;
    let ordem = this.state.ordem;
    let id = document.getElementById("idRela").value;
    let status = document.getElementById("statusRela").value;
    let forma = document.getElementById("formaRela").value;
    let inicio = document.getElementById("inicioRela").value;
    let fim = document.getElementById("fimRela").value;
    this.loader();

    if ((inicio !== "" && fim === "") || (inicio === "" && fim !== "")) {
      toast.error(
        "É necessário informar uma data inicial e uma data final para o período",
        {
          id: "toastPedido",
        }
      );
      this.setState({ pedidos: [] });
      return;
    } else if (new Date(inicio) > new Date(fim)) {
      toast.error("A data inicial deve ser menor que a data final", {
        id: "toastPedido",
      });
      this.setState({ pedidos: [] });
      return;
    } else {
      this.setState({
        filtrando: true,
        loading: true,
        id,
        status,
        forma,
        inicio,
        fim,
      });

      let pedidos = pedidosProp.filter(
        (pedido) =>
          this.filtroId(pedido) &&
          this.filtroStatus(pedido) &&
          this.filtroForma(pedido) &&
          this.filtroData(pedido)
      );

      this.filterMedia(pedidos);

      if (ordem === "Menor - Maior") {
        pedidos.sort((a, b) => {
          if (a.data_hora <= b.data_hora) {
            return -1;
          }
          if (a.data_hora >= b.data_hora) {
            return 1;
          }
          return 0;
        });
      }

      this.setState({ pedidos: pedidos });
      this.setTotalPedidos(pedidos);
    }
  };

  filtroId = (pedido) => {
    let id = this.state.id;
    if (id !== "" && id !== 0) {
      return pedido.id_pedido === parseInt(id);
    } else {
      return pedido;
    }
  };

  filtroStatus = (pedido) => {
    let status = this.state.status;
    if (status !== "" && status !== "Todos") {
      return pedido.status_pedido === status.toUpperCase();
    } else {
      return pedido;
    }
  };

  filtroForma = (pedido) => {
    let forma = this.state.forma;
    if (forma !== "") {
      return pedido.forma_pagamento === forma;
    } else {
      return pedido;
    }
  };

  filtroData = (pedido) => {
    let inicio = this.state.inicio;
    let fim = this.state.fim;
    if (inicio !== "") {
      if (fim !== "") {
        return (
          new Date(pedido.data_hora) >= new Date(`${inicio} 00:00:00`) &&
          new Date(pedido.data_hora) <= new Date(`${fim} 23:59:59`)
        );
      }
    } else {
      return pedido;
    }
  };

  filterMedia = (pedidos) => {
    let dateArr = [];
    let pedidosQty = [];
    let valorQty = [];
    let chkQtyDia = document.getElementById("qtde_media");
    let chkTicket = document.getElementById("valor_media");

    if (pedidos !== null && pedidos !== undefined && pedidos.length !== 0) {
      //eslint-disable-next-line
      for (let [_, ped] of pedidos.entries()) {
        ped.relatorio_data = new Date(ped.data_hora)
          .toLocaleDateString("pt-BR")
          .replaceAll("/", "");
        dateArr.push(ped);
      }
      let pedidosByDate = this.groupBy(dateArr, "relatorio_data");

      for (let key in pedidosByDate) {
        let pedQty = pedidosByDate[key].length;
        let arrValores = [];

        // eslint-disable-next-line
        for (let [_, vrPed] of pedidosByDate[key].entries()) {
          arrValores.push(vrPed.vr_pedido);
        }

        let mediaValor = (
          arrValores.reduce((a, b) => a + b) / arrValores.length
        ).toFixed(2);

        pedidosQty.push(pedQty);
        valorQty.push(Number.parseFloat(mediaValor));
      }

      if (chkQtyDia.checked) {
        let mediaPedidos = Math.trunc(
          pedidosQty.reduce((a, b) => a + b) / pedidosQty.length
        );

        this.setState({ mediaPedidos, mediaPedCheck: true });
      } else {
        this.setState({ mediaPedCheck: false });
      }

      if (chkTicket.checked) {
        let mediaValor = Number.parseFloat(
          valorQty.reduce((a, b) => {
            return a + b;
          }, 0) / valorQty.length
        );

        this.setState({ mediaValor, mediaVrCheck: true });
      } else {
        this.setState({ mediaVrCheck: false });
      }
    }
  };

  groupBy = (objectArray, property) => {
    return objectArray.reduce((accumulator, currentObject) => {
      let key = currentObject[property];
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentObject);
      return accumulator;
    }, {});
  };

  limpaFiltro = () => {
    this.setState({
      filtrando: false,
      loading: true,
      id: 0,
      status: "",
      forma: "",
      inicio: "",
      fim: "",
      pedidos: [],
    });

    document.getElementById("idRela").value = "";
    document.getElementById("statusRela").value = "";
    document.getElementById("formaRela").value = "";
    document.getElementById("inicioRela").valueAsDate = null;
    document.getElementById("fimRela").valueAsDate = null;
    document.getElementById("qtde_media").checked = true;
    document.getElementById("valor_media").checked = true;

    this.loader();
  };

  setTotalPedidos = (pedidos) => {
    let total = 0;
    //eslint-disable-next-line
    for (let [_, pedido] of pedidos.entries()) {
      if (pedido.vr_pedido !== 0) {
        total = total + pedido.vr_pedido;
        total = parseFloat(total);
      }
    }
    this.setState({ totalPedidos: total });
  };

  loader = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  render() {
    const {
      tabIndex,
      filtrando,
      loading,
      pedidos,
      pedido,
      itens,
      subtotal,
      isModalVisible,
      totalPedidos,
      mediaPedidos,
      mediaPedCheck,
      mediaValor,
      mediaVrCheck,
    } = this.state;

    return (
      <>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList className="container">
            <Tab>Pedido</Tab>
          </TabList>
          <TabPanel>
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-xs-3"
                  style={{
                    backgroundColor: "#F8F8F8",
                    border: "solid #EAEAEA 1px",
                    borderRight: "none",
                    borderRadius: "5px 0px 0px 5px",
                    height: "80vh",
                    overflow: "auto",
                  }}
                >
                  <div className="col-xs-12" style={{ paddingTop: "5px" }}>
                    <h4 style={{ fontWeight: "bolder" }}>Filtrar por:</h4>
                    <hr />
                  </div>
                  <div className="col-xs-12">
                    <label>Id do Pedido:</label>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group">
                      <input
                        className="form-control input-md"
                        type="number"
                        id="idRela"
                        onChange={(e) => this.setState({ id: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <label>Status do Pedido:</label>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="statusRela"
                        onChange={(e) =>
                          this.setState({ status: e.target.value })
                        }
                      >
                        <option value=""></option>
                        <option value="Todos">Todos</option>
                        <option value="Pendente">Pendente</option>
                        <option value="Confirmado">Confirmado</option>
                        <option value="Despachado">Despachado</option>
                        <option value="Entregue">Entregue</option>
                        <option value="Cancelado">Cancelado</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <label>Forma de pagamento:</label>
                  </div>
                  <div className="col-xs-12">
                    <select
                      className="form-control"
                      id="formaRela"
                      onChange={(e) => this.setState({ forma: e.target.value })}
                    >
                      <option value=""></option>
                      <option value="Dinheiro">Dinheiro</option>
                      <option value="Cartão - Débito">Cartão - Débito</option>
                      <option value="Cartão - Crédito">Cartão - Crédito</option>
                    </select>
                  </div>
                  <div className="col-xs-12" style={{ marginTop: "10px" }}>
                    <label>De:</label>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group">
                      <input
                        type="date"
                        name="de"
                        id="inicioRela"
                        className="form-control input-md"
                        onChange={(e) =>
                          this.setState({ inicio: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <label>Até:</label>
                  </div>
                  <div className="col-xs-12">
                    <div className="form-group">
                      <input
                        type="date"
                        name="ate"
                        id="fimRela"
                        className="form-control input-md"
                        onChange={(e) => this.setState({ fim: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <label>Ordenar (data):</label>
                  </div>
                  <div className="col-xs-12">
                    <select
                      className="form-control"
                      onChange={(e) => this.setState({ ordem: e.target.value })}
                    >
                      <option value="Menor - Maior">Menor - Maior</option>
                      <option value="Maior - Menor">Maior - Menor</option>
                    </select>
                  </div>
                  {/* ----------- Checkboxes ---------- */}

                  <div className="col-xs-12">
                    <label
                      className="label-relatorio"
                      style={{ marginTop: "16px" }}
                    >
                      <input
                        type="checkbox"
                        id="qtde_media"
                        className="checkbox-relatorio"
                        defaultChecked
                      />
                      Média - pedidos diários
                    </label>
                  </div>
                  <div className="col-xs-12">
                    <label
                      className="label-relatorio"
                      style={{ marginTop: "16px" }}
                    >
                      <input
                        type="checkbox"
                        id="valor_media"
                        className="checkbox-relatorio"
                        defaultChecked
                      />
                      Ticket Médio - diário
                    </label>
                  </div>
                  <div className="col-xs-12">
                    <button
                      className="btn btn-md btn-vk btn-block"
                      style={{
                        outline: "none",
                        marginTop: "16px",
                      }}
                      onClick={() => {
                        this.setFiltro();
                      }}
                    >
                      Filtrar Pedidos
                    </button>
                    {filtrando ? (
                      <button
                        className="btnExc"
                        style={{
                          outline: "none",
                          width: "100%",
                          marginTop: "12px",
                        }}
                        onClick={() => {
                          this.limpaFiltro();
                        }}
                      >
                        Limpar Filtros
                      </button>
                    ) : null}
                  </div>
                </div>
                <div
                  className="col-xs-9"
                  style={{
                    border: "solid #EAEAEA 1px",
                    borderLeft: "none",
                    borderRadius: "0px 5px 0px 0px",
                    height: "7vh",
                    padding: "0px",
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  {filtrando ? (
                    <nav
                      style={{
                        margin: "0px",
                        // padding: "20px 95px"
                        padding: "20px 115px 20px 100px",
                      }}
                    >
                      <div className="col-xs-12">
                        <div className="row">
                          <div className="col-xs-2">
                            <div
                              className="col-xs-12"
                              style={{ textAlign: "center" }}
                            >
                              <b>Id:</b>
                            </div>
                          </div>
                          <div
                            className="col-xs-2"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div
                              className="col-xs-12"
                              style={{ textAlign: "center" }}
                            >
                              <b>Cliente:</b>
                            </div>
                          </div>
                          <div
                            className="col-xs-2"
                            style={{
                              textAlign: "center",
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div
                              className="col-xs-12"
                              style={{ textAlign: "center" }}
                            >
                              <b>Status:</b>
                            </div>
                          </div>
                          <div
                            className="col-xs-2"
                            style={{
                              textAlign: "center",
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div
                              className="col-xs-12"
                              style={{ textAlign: "center" }}
                            >
                              <b>Data:</b>
                            </div>
                          </div>
                          <div
                            className="col-xs-2"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div
                              className="col-xs-12"
                              style={{ textAlign: "center" }}
                            >
                              <b>Forma:</b>
                            </div>
                          </div>
                          <div
                            className="col-xs-2"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div
                              className="col-xs-12"
                              style={{ textAlign: "center" }}
                            >
                              <b>Total:</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  ) : null}
                </div>
                <div
                  className="col-xs-9"
                  style={{
                    border: "solid #EAEAEA 1px",
                    borderTop: "none",
                    borderRadius: "0px 0px 5px 0px",
                    height: "73vh",
                    padding: "0px",
                  }}
                >
                  {!loading ? (
                    <>
                      <div
                        className="col-xs-12"
                        style={{
                          height: "60vh",
                          overflow: "auto",
                          paddingTop: "30px",
                        }}
                      >
                        <div className="col-xs-12 col-pedidos">
                          {pedidos !== null &&
                          pedidos !== undefined &&
                          pedidos.length !== 0 ? (
                            <>
                              {pedidos.map((pedido) => (
                                <PedidoRelatorio
                                  pedido={pedido}
                                  hideModal={() => this.hideModal()}
                                  showModal={() => this.showModal(pedido)}
                                />
                              ))}
                            </>
                          ) : (
                            <div
                              className="col-xs-12"
                              style={{
                                width: "100%",
                                height: "100%",
                                paddingTop: "20%",
                              }}
                            >
                              <div className="col-xs-3"></div>
                              <div
                                className="col-xs-6"
                                style={{
                                  color: "#ff5722",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                <div className="col-xs-12">
                                  <img
                                    src={search}
                                    alt=" "
                                    width="150px"
                                    height="150px"
                                    style={{
                                      margin: "0 auto !important",
                                    }}
                                  />
                                </div>
                                <div
                                  className="col-xs-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <h4>
                                    <b>Nenhum pedido encontrado.</b>
                                  </h4>
                                </div>
                              </div>
                              <div className="col-xs-3"></div>
                            </div>
                          )}
                        </div>
                      </div>
                      {pedidos.length !== 0 && filtrando ? (
                        <>
                          <div className="col-xs-12">
                            <hr style={{ marginBottom: "0px" }} />
                          </div>
                          <div className="col-xs-12">
                            <div
                              className="col-xs-12"
                              style={{ marginTop: "15px" }}
                            >
                              <div
                                className="col-xs-3"
                                style={{ textAlign: "center" }}
                              >
                                {mediaPedCheck ? (
                                  <div className="col-xs-12">
                                    <h4>
                                      Média - pedidos diários:
                                      <div className="w-100"></div>
                                      <b>{mediaPedidos}</b>
                                    </h4>
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="col-xs-3"
                                style={{ textAlign: "center" }}
                              >
                                {mediaVrCheck ? (
                                  <div className="col-xs-12">
                                    <h4>
                                      Ticket Médio por Venda
                                      <div className="w-100"></div>
                                      <b>
                                        {new Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(mediaValor)}
                                      </b>
                                    </h4>
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="col-xs-3"
                                style={{ textAlign: "center" }}
                              >
                                <div className="col-xs-12">
                                  <h4>
                                    Pedidos encontrados:
                                    <div className="w-100"></div>
                                    <b>{pedidos.length}</b>
                                  </h4>
                                </div>
                              </div>
                              <div
                                className="col-xs-3"
                                style={{ textAlign: "center" }}
                              >
                                <div className="col-xs-12">
                                  <h4>
                                    Valor total:
                                    <div
                                      className="w-100"
                                      style={{ marginTop: "10px" }}
                                    ></div>
                                    <b>
                                      {new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(totalPedidos)}
                                    </b>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <div className="loaderRela" id="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
        <ModalRelatorios
          ariaHideApp={false}
          isOpen={isModalVisible}
          style={customStyles}
          contentLabel="Relatório Pedido"
          onRequestClose={() => this.hideModal()}
        >
          <ModalRelatorio pedido={pedido} itens={itens} subtotal={subtotal} />
        </ModalRelatorios>
      </>
    );
  }
}

export default Relatorios;

const customStyles = {
  content: {
    trasnition: "0.2s",
    inset: "0px 0px 0px 45%",
    borderRadius: "5px 0px 0px 5px",
  },
  overlay: {
    zIndex: 2,
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};
