import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../../service/api/PrestAPI";
import serializeForm from "form-serialize";
import { toast } from "react-hot-toast";
import "./styles.css";

class ComponentItem extends Component {
  static propTypes = {
    modo: PropTypes.string.isRequired,
    item: PropTypes.object,
    ocultar: PropTypes.bool.isRequired,
    onClosed: PropTypes.func.isRequired,
    getCatComplementos: PropTypes.func.isRequired,
  };

  state = {
    btnClick: "",
    loading: false,
    complemento: {},
  };

  componentDidMount() {
    const { item, modo } = this.props;
    if (modo === "editar") {
      this.setState({ complemento: this.props.item });
    } else if (modo === "novo") {
      const complement = {
        id_categoria_complemento: item.id_categoria_complemento,
        id_complemento: item.id_complemento,
        nome_complemento: "",
        descricao_complemento: "",
        vr_adicional: 0.0,
        cod_externo: "",
      };
      this.setState({ complemento: complement });
    }
  }

  handleSubmit = (event) => {
    const { modo, item } = this.props;
    const { btnClick } = this.state;
    this.setState({ loading: true });

    event.preventDefault();
    const form = event.target;
    const data = serializeForm(form, { hash: true });
    data.id_complemento = Number.parseInt(item.id_complemento, 10);

    if (modo === "novo") {
      if (btnClick === "btnSalvar") {
        this.saveComplementItem(data);
      }
    }
    if (modo === "editar") {
      if (btnClick === "btnSalvar") {
        this.editComplementItem(data);
      }
      if (btnClick === "btnExcluir") {
        this.onExcluirClick(data);
      }
    }
    this.props.getCatComplementos();
    this.setState({ loading: false });
  };

  saveComplementItem(complemento) {
    let statusComplemento = document.getElementById("status_complemento");
    const item = {
      id_categoria_complemento: Number.parseInt(
        complemento.id_categoria_complemento,
        10
      ),
      id_complemento: Number.parseInt(complemento.id_complemento, 10),
      nome_complemento: complemento.nome_complemento,
      descricao_complemento: complemento.descricao_complemento,
      vr_adicional: parseFloat(complemento.vr_adicional),
      cod_externo: complemento.cod_externo,
      sequencia: Number.parseInt(complemento.sequencia, 10),
    };
    if (statusComplemento.checked) {
      item.status_complemento = 1;
    } else {
      item.status_complemento = 0;
    }
    PrestAPI.add("ProdutoComplementoItem", item)
      .then((value) => {
        if (value.status === 200) {
          toast.success("Complemento adicionado com sucesso!");
        }
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  editComplementItem(complemento) {
    let statusComplemento = document.getElementById("status_complemento");
    const item = {
      id_categoria_complemento: Number.parseInt(
        complemento.id_categoria_complemento,
        10
      ),
      id_complemento: Number.parseInt(complemento.id_complemento, 10),
      nome_complemento: complemento.nome_complemento,
      descricao_complemento: complemento.descricao_complemento,
      vr_adicional: parseFloat(complemento.vr_adicional),
      cod_externo: complemento.cod_externo,
      sequencia: Number.parseInt(complemento.sequencia, 10),
    };
    if (statusComplemento.checked) {
      item.status_complemento = 1;
    } else {
      item.status_complemento = 0;
    }
    PrestAPI.update(
      "ProdutoComplementoItem",
      `?idComplemento=${item.id_complemento}`,
      item
    )
      .then((value) => {
        if (value.status === 200) {
          toast.success("Salvo!");
        }
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  onExcluirClick(complemento) {
    PrestAPI.del(
      "ProdutoComplementoItem",
      `?idCatComplemento=${complemento.id_categoria_complemento}&idComplemento=${complemento.id_complemento}`
    )
      .then((value) => {
        if (value.status === 200) {
          toast.success("Complemento excluido!");
        }
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  render() {
    const { ocultar, modo } = this.props;
    const { complemento } = this.state;

    return (
      <div>
        {ocultar ? (
          <div className="modal-complement-item">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-sm-12 modal-complement-title">
                  <label>Complemento:</label>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="form-group col-sm-5">
                  <label>Nome complemento:</label>
                  <input
                    type="text"
                    name="nome_complemento"
                    defaultValue={complemento.nome_complemento}
                    className="form-control input-sm"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-sm-3">
                  <label>Cod Externo:</label>
                  <input
                    type="text"
                    name="cod_externo"
                    defaultValue={complemento.cod_externo}
                    className="form-control input-sm"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-sm-1">
                  <label>Sequência:</label>
                  <input
                    type="number"
                    name="sequencia"
                    defaultValue={complemento.sequencia}
                    className="form-control input-sm"
                    placeholder=""
                    style={{ width: "50px", textAlign: "center" }}
                  />
                </div>
                <div className="form-group col-sm-2">
                  <div className="col-sm-12">
                    <label htmlFor="status">Status:</label>
                  </div>
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      name="status_complemento"
                      defaultChecked={
                        modo === "novo"
                          ? true
                          : this.props.item.status_complemento === 1
                          ? true
                          : false
                      }
                      id="status_complemento"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginTop: "1px",
                      }}
                    />
                  </div>
                </div>
                <div className="form-group col-sm-3">
                  <label>Descrição:</label>
                  <input
                    type="text"
                    name="descricao_complemento"
                    defaultValue={complemento.descricao_complemento}
                    className="form-control input-sm"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-sm-2">
                  <label>Preço:</label>
                  <input
                    type="text"
                    name="vr_adicional"
                    defaultValue={complemento.vr_adicional}
                    className="form-control input-sm"
                    placeholder=""
                  />
                </div>
                <div className="col-sm-3" style={{ marginTop: "25px" }}>
                  {modo === "editar" ? (
                    <div>
                      <button
                        className="form-control input-sm btnExc"
                        name="btnExcluir"
                        onClick={() =>
                          this.setState({ btnClick: "btnExcluir" })
                        }
                      >
                        Excluir
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className="col-sm-3" style={{ marginTop: "25px" }}>
                  <button
                    className="form-control input-sm btn-vk"
                    name="btnSalvar"
                    onClick={() => this.setState({ btnClick: "btnSalvar" })}
                    style={{ outline: "none" }}
                  >
                    Salvar
                  </button>
                </div>
              </div>
              <div className="form-group col-sm-1" hidden={true}>
                <label>ID:</label>
                <input
                  type="text"
                  name="id_categoria_complemento"
                  defaultValue={complemento.id_categoria_complemento}
                />
              </div>
            </form>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ComponentItem;
