import React from "react";
import PropTypes from "prop-types";

class ColorSelect extends React.Component {
  state = {
    color_options: [
      {
        a: "#FF5722",
        b: "#FE8862",
        c: "#FFBAA4",
        d: "#FFE6DE",
        e: "#FFE6DE",
      },
      {
        a: "#000000",
        b: "#464646",
        c: "#7B7B7B",
        d: "#D8D8D8",
        e: "#FFFFFF",
      },
      {
        a: "#750000",
        b: "#C12323",
        c: "#FF0000",
        d: "#8AF80D",
        e: "#C6E6AC",
      },
    ],
    selectedOptionIndex: 0,
  };

  componentDidMount() {
    this.notifyColorSelect();
  }

  notifyColorSelect = () => {
    const { onColorSelect } = this.props;
    const { color_options, selectedOptionIndex } = this.state;

    if (onColorSelect) {
      onColorSelect(color_options[selectedOptionIndex]);
    }
  };

  handleClick = (index) => {
    this.setState({ selectedOptionIndex: index }, this.notifyColorSelect);
  };

  render() {
    const { selectedOptionIndex, color_options } = this.state;

    return (
      <div className="containerColorPicker" style={{ marginBottom: "20px" }}>
        <h4 className="text-center">Minha Loja</h4>
        {color_options.map((option, index) => (
          <div
            key={index}
            style={
              selectedOptionIndex === index
                ? styles.selectedSwatch
                : styles.swatch
            }
            onClick={() => this.handleClick(index)}
          >
            {Object.values(option).map((color, idx) => (
              <div key={idx} style={{ ...styles.color, background: color }} />
            ))}
          </div>
        ))}
      </div>
    );
  }
}

const styles = {
  color: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    border: "1px solid #FF5722",
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-around",
    margin: "5px 0",
  },
  selectedSwatch: {
    padding: "5px",
    background: "#FFE6DE",
    boxShadow: "0 0 0 2px rgba(0,0,0,.2)",
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-around",
  },
};

ColorSelect.propTypes = {
  onColorSelect: PropTypes.func,
};

ColorSelect.defaultProps = {
  onColorSelect: null,
};

export default ColorSelect;
