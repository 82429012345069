import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import * as PrestAPI from "../../service/api/PrestAPI";
import ReactLoading from "react-loading";
import "../Produto/Components/styles.css";
import { toast } from "react-hot-toast";

class TaxaEntregaList extends Component {
  static propTypes = {
    estabelecimento: PropTypes.object.isRequired,
    editTaxa: PropTypes.func.isRequired,
    setNovaTaxa: PropTypes.func.isRequired,
  };

  state = {
    taxas: [],
    loading: true,
    tipoFiltro: "Todos",
  };

  async componentDidMount() {
    const estabelecimento = this.props.estabelecimento;
    var loading = this.state.loading;
    if (loading) {
      await PrestAPI.get(
        "Taxa",
        `id_estabelecimento=${estabelecimento.id_estabelecimento}`
      ).then((taxas) => {
        this.setState({ taxas });
      });

      this.props.setNovaTaxa();
      this.setState({ tipoFiltro: "Todos" });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  }

  render() {
    const { editTaxa, estabelecimento } = this.props;
    const { taxas, loading, tipoFiltro } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-1"></div>
          <div className="col-sm-4">
            <h3>Taxas</h3>
          </div>
          <div className="col-sm-2" style={{ textAlign: "end" }}>
            <label>Tipo do Cadastro:</label>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <select
                className="form-control"
                id="tipoFiltro"
                onChange={(e) => this.setState({ tipoFiltro: e.target.value })}
              >
                <option>Todos</option>
                <option>Bairro</option>
                <option>CEP</option>
              </select>
            </div>
          </div>
          <div className="col-sm-2">
            <Link
              to="/taxa_entrega-add"
              className="btn btn-vk btn-block"
              onClick={() => this.props.setNovaTaxa()}
            >
              Adicionar Taxa
            </Link>
          </div>
          <div className="col-sm-1"></div>
        </div>
        {!loading ? (
          <div className="row table-responsive">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="col-xs-1">Id.Taxa</th>
                    <th className="col-xs-9">Bairro</th>
                    <th className="col-xs-1">Vr.Taxa</th>
                    <th className="col-xs-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {taxas
                    .filter((taxa) => {
                      if (tipoFiltro === "Bairro") {
                        return taxa.cep_inicio === "";
                      } else if (tipoFiltro === "CEP") {
                        return taxa.cep_inicio !== "";
                      } else {
                        return taxa;
                      }
                    })
                    .map((taxa) => (
                      <tr key={taxa.id_taxa}>
                        <td>{taxa.id_taxa}</td>
                        <td>{taxa.bairro}</td>
                        <td>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(taxa.valor_taxa)}
                        </td>
                        <td>
                          <button
                            className="btn btn-vk btn-block"
                            onClick={() => editTaxa(taxa)}
                          >
                            Editar
                          </button>
                        </td>
                        <td>
                          <button
                            className="btnExc"
                            onClick={() => {
                              toast.error(
                                <div className="col-xs-12">
                                  <div className="col-xs-12">
                                    <b>{`Id taxa: ${taxa.id_taxa}\n${taxa.bairro}`}</b>
                                    {`\nTem certeza?`}
                                    <hr />
                                  </div>
                                  <div className="col-xs-12">
                                    <div className="w-100"></div>
                                    <div className="col-xs-6">
                                      <button
                                        onClick={() => {
                                          let taxaExc = {
                                            id_taxa: taxa.id_taxa,
                                            id_estabelecimento:
                                              estabelecimento.id_estabelecimento,
                                          };
                                          PrestAPI.delTaxa("Taxa", taxaExc)
                                            .then((res) => {
                                              if (res.status === 202) {
                                                toast.success(
                                                  `Taxa ${taxa.id_taxa} excluida com sucesso`,
                                                  {
                                                    id: taxa.id_taxa,
                                                  }
                                                );
                                                PrestAPI.get(
                                                  "Taxa",
                                                  `id_estabelecimento=${estabelecimento.id_estabelecimento}`
                                                ).then((taxas) => {
                                                  this.setState({ taxas: [] });
                                                  this.setState({ taxas });
                                                });
                                              }
                                            })
                                            .catch(() => {
                                              toast.error(
                                                `Não foi possível excluir Taxa ${taxa.id_taxa}..\nTente mais tarde`,
                                                {
                                                  id: taxa.id_taxa,
                                                }
                                              );
                                            });
                                          setTimeout(
                                            () => toast.dismiss(taxa.id_taxa),
                                            5000
                                          );
                                        }}
                                        className="btn btn-sm btn-primary"
                                        style={{
                                          padding: "6px 25px",
                                          outline: "none",
                                        }}
                                      >
                                        Sim
                                      </button>
                                    </div>
                                    <div className="col-xs-6">
                                      <button
                                        className="btnExc"
                                        onClick={() =>
                                          toast.dismiss(taxa.id_taxa)
                                        }
                                      >
                                        Não
                                      </button>
                                    </div>
                                  </div>
                                </div>,
                                {
                                  id: taxa.id_taxa,
                                }
                              );
                            }}
                            style={{ padding: "4px 12px" }}
                          >
                            Excluir
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="col-sm-1"></div>
          </div>
        ) : (
          <div className="loaderApp">
            <ReactLoading
              type="spinningBubbles"
              color="#ff5722"
              height={100}
              width={100}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TaxaEntregaList;
