import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Style.css";

import * as PrestAPI from "../../service/api/PrestAPI";

class Produto extends Component {
  static propTypes = {
    editProduto: PropTypes.func.isRequired,
    editGrupo: PropTypes.func.isRequired,
    estabelecimento: PropTypes.object,
    redirectAdd: PropTypes.func,
    setSelectedCategoria: PropTypes.func,
  };

  state = {
    categorias: [],
    produtos: [],
    statusFiltro: "Ativo",
    loading: true,
  };
  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;

    const estabelecimento = this.props.estabelecimento;

    try {
      const respCategorias = await PrestAPI.get(
        "ProdutoGrupo",
        `estab=${estabelecimento.id_estabelecimento}&modo=retaguarda`
      );

      if (this._isMounted) {
        this.setState({ categorias: respCategorias.categorias });
      }

      const respProdutos = await PrestAPI.get(
        "Produto",
        `estab=${estabelecimento.id_estabelecimento}&modo=retaguarda`
      );

      if (this._isMounted) {
        this.setState({ produtos: respProdutos.produtos });
        setTimeout(() => {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }, 500);
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  }
  componentDidUpdate() {
    const categorias = this.state.categorias;
    categorias.forEach((categoria) => {
      const element = document.getElementById(`novoProd${categoria.id_grupo}`);
      if (element) {
        element.removeEventListener("click", this.handleNewProductClick);
      }
    });

    categorias.forEach((categoria) => {
      const element = document.getElementById(`novoProd${categoria.id_grupo}`);
      if (element) {
        element.addEventListener("click", this.handleNewProductClick(categoria));
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const categorias = this.state.categorias;
    categorias.forEach((categoria) => {
      const element = document.getElementById(`novoProd${categoria.id_grupo}`);
      if (element) {
        element.removeEventListener("click", this.handleNewProductClick);
      }
    });
  }

  handleNewProductClick = (categoria) => (e) => {
    let option = categoria.descricao;
    this.props.setSelectedCategoria(option);
    this.props.redirectAdd();
    e.stopPropagation();
  };

  render() {
    const { editProduto, editGrupo, estabelecimento } = this.props;

    const { categorias, produtos, statusFiltro, loading } = this.state;

    return (
      <div className="container">
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-sm-3">
            <Link to="/produto_grupo-add" className="btn btn-block btn-vk">
              Adicionar categoria
            </Link>
          </div>
          <div className="col-sm-3"></div>
          <div className="col-sm-3" style={{ textAlign: "end" }}>
            <label>Status do Produto:</label>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <select
                className="form-control"
                id="statusFiltro"
                onChange={(e) =>
                  this.setState({ statusFiltro: e.target.value })
                }
              >
                <option>Ativo</option>
                <option>Inativo</option>
                <option>Todos</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="panel-group"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="panel-group">
            {categorias === null || categorias === undefined
              ? null
              : categorias.map((categoria) => (
                  <div
                    className="panel panel-default"
                    key={categoria.id_grupo}
                    style={{ marginTop: "20px" }}
                  >
                    <div
                      className="panel-heading"
                      data-toggle="collapse"
                      href={`#collapse${categoria.id_grupo}`}
                      style={{
                        cursor: "pointer",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="row">
                        <div
                          className="col-md-6"
                          style={{ textAlign: "center" }}
                        >
                          <h3>{categoria.descricao}</h3>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-vk btn-block"
                            style={{ marginTop: "10px" }}
                            id={`novoProd${categoria.id_grupo}`}
                          >
                            Novo produto
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-vk btn-block"
                            onClick={() => editGrupo(categoria)}
                            style={{ marginTop: "10px" }}
                          >
                            Editar categoria
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      id={`collapse${categoria.id_grupo}`}
                      className="panel-collapse collapse in"
                      style={{ padding: "10px" }}
                    >
                      {!loading ? (
                        produtos === null || produtos === undefined ? null : (
                          produtos
                            .filter((p) => {
                              if (statusFiltro === "Ativo") {
                                return (
                                  p.id_grupo === categoria.id_grupo &&
                                  p.status_produto === 1
                                );
                              } else if (statusFiltro === "Inativo") {
                                return (
                                  p.id_grupo === categoria.id_grupo &&
                                  p.status_produto === 0
                                );
                              } else {
                                return p.id_grupo === categoria.id_grupo;
                              }
                            })
                            .map((produto) => (
                              <div key={produto.id_produto} style={prodStyle}>
                                <div className="row tabProdudo">
                                  <div
                                    className="col-sm-2"
                                    style={{ textAlign: "center" }}
                                  >
                                    <img
                                      src={
                                        produto.imagem !== ""
                                          ? produto.imagem
                                          : estabelecimento.imagem
                                      }
                                      alt="imgProd"
                                      style={{
                                        maxWidth: "50%",
                                        height: "50px",
                                        borderRadius: "20%",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="col-sm-4"
                                    style={{ fontSize: "17px" }}
                                  >
                                    {produto.nome}
                                  </div>
                                  {/* Tag vai aqui */}
                                  <div className="col-sm-2">
                                    <h4>
                                      {new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(produto.vr_unitario)}
                                    </h4>
                                  </div>
                                  <div className="col-sm-2">
                                    <b style={{ color: "orange" }}>
                                      {/* -------------------OFERTA------------------------- */}
                                    </b>
                                  </div>
                                  <div
                                    className="col-sm-2"
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      className="btn btn-vk-edit btn-md"
                                      onClick={() => {
                                        let option = "";

                                        this.props.setSelectedCategoria(option);
                                        editProduto(produto);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))
                        )
                      ) : (
                        <div className="loader" id="loader">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Produto;

const prodStyle = {
  padding: 5,
  // borderStyle: 'solid',
  // borderWidth: 1,
};
