import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import "./Login.css";
import { toast } from "react-hot-toast";

var CryptoJS = require("crypto-js");

class Login extends Component {
  static propTypes = {
    login: PropTypes.func,
    imagem: PropTypes.string,
  };

  state = {
    loading: true,
    usuario: "",
    senha: "",
  };

  handleSubmit = () => {
    let cnpj = this.state.usuario;
    let senha = this.state.senha;

    if (cnpj !== "" && senha !== "") {
      let data = {
        cnpj: this.state.usuario,
        senha: this.state.senha,
      };

      if (this.props.login) {
        this.props.login(data);
      }
    } else {
      toast.error("Há campos vazios no formulário", {
        id: "erroLogin",
        duration: 1000,
      });
    }
  };

  async componentDidMount() {
    if (this.state.loading) {
      let loggedDados = localStorage.getItem("Estab");

      if (loggedDados !== null) {
        let decryptDados = CryptoJS.AES.decrypt(
          loggedDados,
          process.env.REACT_APP_ENCRYPT_PASS
        );
        let decryptedDados = JSON.parse(
          decryptDados.toString(CryptoJS.enc.Utf8)
        );

        const filtroDados = {
          cnpj: decryptedDados.uid,
          senha: decryptedDados.ticket,
        };

        await this.props.login(filtroDados);
      } else {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 300);
      }
    }
  }

  render() {
    const { loading } = this.state;
    const { imagem } = this.props;
    return (
      <>
        {!loading ? (
          <div className="container-fluid login-container">
            <div className="row">
              <div className="form-group col-sm-2"></div>
              <div className="form-group col-sm-8">
                <img
                  className="col-sm-12 imagem-login"
                  src={imagem}
                  alt="..."
                />
                <label>Usuário:</label>
                <input
                  name="cnpj"
                  type="text"
                  className="form-control input-lg"
                  placeholder="Digite o usuário"
                  required
                  style={{ marginBottom: "16px" }}
                  onChange={(e) => this.setState({ usuario: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleSubmit();
                    }
                  }}
                />
                <label>Senha:</label>
                <input
                  name="senha"
                  type="password"
                  className="form-control input-lg"
                  placeholder="Digite sua senha"
                  required
                  style={{ marginBottom: "16px" }}
                  onChange={(e) => this.setState({ senha: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleSubmit();
                    }
                  }}
                />
                <button
                  className="btn btn-lg btn-block btn-vk"
                  onClick={() => this.handleSubmit()}
                >
                  Entrar
                </button>
              </div>
              <div className="form-group col-sm-2"></div>
            </div>
          </div>
        ) : (
          <div className="loaderApp">
            <ReactLoading
              type="spinningBubbles"
              color="#ff5722"
              height={100}
              width={100}
            />
          </div>
        )}
      </>
    );
  }
}

export default Login;
