import React, { Component } from "react";
import PropTypes from "prop-types";

class GrupoList extends Component {
  static propTypes = {
    editGrupo: PropTypes.func.isRequired,
    categorias: PropTypes.array.isRequired,
  };

  render() {
    const { editGrupo, categorias } = this.props;

    return (
      <div className="row table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th className="col-xs-1">Id.Grupo</th>
              <th className="col-xs-9">Descrição</th>
              <th className="col-xs-1"></th>
            </tr>
          </thead>
          <tbody>
            {categorias.map((grupo) => (
              <tr key={grupo.id_grupo}>
                <td>{grupo.id_grupo}</td>
                <td>{grupo.descricao}</td>
                <td>
                  <button
                    className="btn btn-primary btn-block btn-vk"
                    onClick={() => editGrupo(grupo)}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default GrupoList;
