import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import Turno from "./Turno";
import ModalAgendaComponent from "react-modal";
import ModalAgenda from "./ModalAgenda";
import * as PrestAPI from "../../../service/api/PrestAPI";

class AgendaComponent extends Component {
  static propTypes = {
    produto: PropTypes.object.isRequired,
    agenda: PropTypes.object.isRequired,
    disponibilidade: PropTypes.array.isRequired,
    numero: PropTypes.number,
    setAgenda: PropTypes.func,
    isNovaAgenda: PropTypes.func,
    editNovaAgenda: PropTypes.func,
    tipo: PropTypes.string.isRequired,
    complemento: PropTypes.object,
  };

  state = {
    dias: "",
    disponibilidade: [],
    isComponentModalVisible: false,
  };

  verificaDias = (dias) => {
    let key = this.props.numero;
    let dom = document.querySelector(`#dom${key}`);
    let seg = document.querySelector(`#seg${key}`);
    let ter = document.querySelector(`#ter${key}`);
    let qua = document.querySelector(`#qua${key}`);
    let qui = document.querySelector(`#qui${key}`);
    let sex = document.querySelector(`#sex${key}`);
    let sab = document.querySelector(`#sab${key}`);

    if (dias.includes("dom")) {
      dom.classList.add("active");
    }
    if (dias.includes("seg")) {
      seg.classList.add("active");
    }
    if (dias.includes("ter")) {
      ter.classList.add("active");
    }
    if (dias.includes("qua")) {
      qua.classList.add("active");
    }
    if (dias.includes("qui")) {
      qui.classList.add("active");
    }
    if (dias.includes("sex")) {
      sex.classList.add("active");
    }
    if (dias.includes("sab")) {
      sab.classList.add("active");
    }
  };

  showModal = () => {
    this.setState({ isComponentModalVisible: true });
    this.props.editNovaAgenda();
  };

  hideModal = () => {
    this.setState({ isComponentModalVisible: false });
  };

  componentDidMount = () => {
    let dias = this.props.agenda.dias;
    let disponibilidade = this.props.disponibilidade;
    this.setState({ dias, disponibilidade });

    this.verificaDias(dias);

    document
      .getElementById(`${this.props.agenda.descricao}`)
      .addEventListener("click", (e) => {
        this.showModal();

        e.stopPropagation();
      });

    document
      .getElementById(`Exc${this.props.agenda.descricao}`)
      .addEventListener("click", (e) => {
        if (this.props.tipo === "produto") {
          PrestAPI.del(
            "ProdutoAgenda",
            `?idProduto=${this.props.produto.id_produto}&descricao=${this.props.agenda.descricao}`
          )
            .then((result) => {
              if (result.status === 200) {
                PrestAPI.get(
                  `Produto?id_produto=${this.props.produto.id_produto}&modo=retaguarda`
                )
                  .then((res) => {
                    if (
                      res.produtos[0].agenda !== null ||
                      res.produtos[0].agenda !== undefined
                    ) {
                      let agenda = res.produtos[0].agenda;

                      this.props.setAgenda(agenda);
                    } else {
                      this.props.setAgenda([]);
                    }
                    alert("Salvo!");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (this.props.tipo === "complemento") {
          PrestAPI.del(
            "ProdutoComplementoItemAgenda",
            `?idComplemento=${this.props.complemento.id_complemento}&descricao=${this.props.agenda.descricao}`
          )
            .then((result) => {
              if (result.status === 200) {
                PrestAPI.get(
                  "ProdutoComplemento",
                  `estab=${this.props.produto.id_estabelecimento}&idProduto=${this.props.produto.id_produto}&modo=retaguarda`
                )
                  .then((result) => {
                    var arrComp = [];

                    if (
                      result.complementos !== null ||
                      result.complementos !== undefined
                    ) {
                      // eslint-disable-next-line
                      for (let [_, cat] of result.complementos.entries()) {
                        if (
                          cat.id_categoria_complemento ===
                          this.props.complemento.id_categoria_complemento
                        ) {
                          for (let i = 0; i < cat.itens.length; i++) {
                            if (
                              cat.itens[i].id_complemento ===
                              this.props.complemento.id_complemento
                            ) {
                              arrComp.push(cat.itens[i]);
                            }
                          }
                        }
                      }
                    }
                    if (
                      arrComp[0].agenda !== null ||
                      arrComp[0].agenda !== undefined
                    ) {
                      let agenda = arrComp[0].agenda;

                      this.props.setAgenda(agenda);
                    } else {
                      this.props.setAgenda([]);
                    }
                    alert("Salvo!");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {});
        }

        e.stopPropagation();
      });
  };

  render() {
    const { agenda, numero, produto, complemento, tipo } = this.props;
    const { disponibilidade, isComponentModalVisible } = this.state;
    return (
      <div className="container-fluid">
        <div
          className="panel-group"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="panel panel-default">
            <div
              className="panel-heading"
              data-toggle="collapse"
              href={`#collapse${numero}`}
              style={{
                cursor: "pointer",
                borderBottom: "1px solid #ddd",
              }}
            >
              <div className="row">
                <div className="col-md-7" style={{ textAlign: "center" }}>
                  <h3>{agenda.descricao}</h3>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-vk btn-block"
                    style={{ marginTop: "10px" }}
                    id={`${agenda.descricao}`}
                  >
                    Editar agenda
                  </button>
                </div>
                <div className="col-md-2">
                  <button
                    className="btnExc btnBlock"
                    style={{
                      marginTop: "10px",
                      paddingTop: "5px",
                      width: "100%",
                    }}
                    id={`Exc${agenda.descricao}`}
                  >
                    Excluir
                  </button>
                </div>
              </div>
            </div>
            <div
              className="panel-collapse collapse in"
              id={`collapse${numero}`}
            >
              <div className="container-fluid">
                <label style={{ marginTop: "10px" }}>Dias da semana:</label>
                <div className="w-100"></div>
                <div
                  className="btn-group"
                  data-toggle="buttons"
                  style={{ zIndex: "0" }}
                >
                  <label
                    className="btn btnExc btn-md btnDias"
                    id={`dom${numero}`}
                    style={{ pointerEvents: "none" }}
                  >
                    <input type="checkbox" name="dom" id="dia" />D
                  </label>
                  <label
                    className="btn btnExc btn-md btnDias"
                    id={`seg${numero}`}
                    style={{ pointerEvents: "none" }}
                  >
                    <input type="checkbox" name="seg" id="dia" />S
                  </label>
                  <label
                    className="btn btnExc btn-md btnDias"
                    id={`ter${numero}`}
                    style={{ pointerEvents: "none" }}
                  >
                    <input type="checkbox" name="ter" id="dia" />T
                  </label>
                  <label
                    className="btn btnExc btn-md btnDias"
                    id={`qua${numero}`}
                    style={{ pointerEvents: "none" }}
                  >
                    <input type="checkbox" name="qua" id="dia" />Q
                  </label>
                  <label
                    className="btn btnExc btn-md btnDias"
                    id={`qui${numero}`}
                    style={{ pointerEvents: "none" }}
                  >
                    <input type="checkbox" name="qui" id="dia" />Q
                  </label>
                  <label
                    className="btn btnExc btn-md btnDias"
                    id={`sex${numero}`}
                    style={{ pointerEvents: "none" }}
                  >
                    <input type="checkbox" name="sex" id="dia" />S
                  </label>
                  <label
                    className="btn btnExc btn-md btnDias"
                    id={`sab${numero}`}
                    style={{ pointerEvents: "none" }}
                  >
                    <input type="checkbox" name="sab" id="dia" />S
                  </label>
                </div>
                <hr />
                {disponibilidade.map((turno, index) => (
                  <Turno key={index} index={index} turno={turno} />
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* -------------------MODAL ---------------------------- */}
        <ModalAgendaComponent
          ariaHideApp={false}
          isOpen={isComponentModalVisible}
          style={customStyles}
          contentLabel="Modal Agendamento"
          onRequestClose={() => this.hideModal()}
        >
          <ModalAgenda
            hideModal={() => this.hideModal()}
            modo={tipo}
            produto={produto}
            setAgenda={(dado) => this.props.setAgenda(dado)}
            item={agenda}
            complemento={complemento}
          />
        </ModalAgendaComponent>
      </div>
    );
  }
}

export default AgendaComponent;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};
