import React from "react";
import Slider from "react-slick";
import { toast } from "react-hot-toast";
import * as PrestAPI from "../../service/api/PrestAPI";
import Anoteja from "../../assets/imagens/anoteja.png";
import Monitor from "../../assets/imagens/monitor.png";
import Vempraka from "../../assets/imagens/vempraka.svg";
import AnotejaLogo from "../../assets/imagens/anotejalogo.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Parceiro.css";
import { formatCNPJ, formatPhone } from "../../utils/formatters";
import { validateCNPJ } from "../../utils/validateCnpj";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

class Parceiro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categorias: null,
      plano: this.props.name,
    };
  }

  addEstabelecimento = async (estabelecimento) => {
    try {
      const result = await PrestAPI.add("Estabelecimento", estabelecimento);
      if (result.status === 200) {
        toast.success("Estabelecimento adicionado com sucesso");
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error(error.message || "Erro ao adicionar o estabelecimento");
    }
  };

  getCategorias = async () => {
    try {
      const result = await PrestAPI.get("Categorias");
      this.setState({ categorias: result.categorias });
    } catch (error) {
      toast.error("Erro ao buscar categorias");
    }
  };

  componentDidMount() {
    this.getCategorias();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    const validationSchema = Yup.object().shape({
      razao_social: Yup.string().required("Nome da loja é obrigatório"),
      telefone: Yup.string().required("Telefone é obrigatório"),
      cnpj: Yup.string()
        .required("CNPJ é obrigatório")
        .test("valid-cnpj", "CNPJ inválido", (value) => validateCNPJ(value)),
      municipio: Yup.string().required("Cidade é obrigatória"),
      email: Yup.string()
        .email("Email inválido")
        .required("Email é obrigatório"),
      senha: Yup.string().required("Senha é obrigatória"),
      confirmarSenha: Yup.string()
        .oneOf([Yup.ref("senha"), null], "As senhas devem corresponder")
        .required("Confirmação de senha é obrigatória"),
      segmento: Yup.string().required("Segmento é obrigatório"),
    });

    return (
      <div
        className="page-parceiro container-fluid"
        style={{
          backgroundColor:
            this.state.plano === "anoteja" ? "#FDB10B" : "#FFFFFF",
        }}
      >
        <div className="container-fluid">
          <header>
            <img
              alt="Logo"
              src={this.state.plano === "anoteja" ? AnotejaLogo : Vempraka}
              className="logo"
            />
          </header>
          <section className="col-md-8" id="slide">
            <h1 className="title-parceiro">Seja nosso parceiro</h1>
            <div>
              <Slider {...settings}>
                <div>
                  <img
                    src={Anoteja}
                    alt="Slide 1"
                    className="img-responsive"
                    style={{ margin: "auto" }}
                  />
                </div>
                <div>
                  <img
                    src={Monitor}
                    alt="Slide 2"
                    className="img-responsive"
                    style={{ margin: "auto" }}
                  />
                </div>
                <div>
                  <img
                    src={Anoteja}
                    alt="Slide 3"
                    className="img-responsive"
                    style={{ margin: "auto" }}
                  />
                </div>
              </Slider>
            </div>
          </section>
          <section className="col-md-4">
            <Formik
              initialValues={{
                razao_social: "",
                telefone: "",
                cnpj: "",
                municipio: "",
                email: "",
                senha: "",
                confirmarSenha: "",
                segmento: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                const estabelecimentoData = {
                  ...values,
                  tempo_minimo_entrega: 0,
                  tipo_venda_plataforma: 0,
                  permite_pagamento_online: 0,
                  permite_uso_vempraka: 0,
                  exibe_preco_apenas_cliente_logado: 0,
                  habilita_cadastro_proprio_cliente: 0,
                  desabilita_todos_tipo_venda: 0,
                  habilita_marketplace_vempraka: 0,
                  status: 0,
                  latitude: 0,
                  longitude: 0,
                };

                this.addEstabelecimento(estabelecimentoData);

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values }) => (
                <Form className="form-horizontal" style={{ padding: "15px" }}>
                  <h3
                    className="text-center"
                    style={{
                      color:
                        this.state.plano === "anoteja" ? "#FFFFFF" : "#000000",
                    }}
                  >
                    Cadastro
                  </h3>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="razao_social"
                      className="form-control input-lg"
                      placeholder="Nome da loja"
                    />
                    <ErrorMessage
                      name="razao_social"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="telefone"
                      className="form-control input-lg"
                      placeholder="Tel. WhatsApp"
                      value={formatPhone(values.telefone)}
                    />
                    <ErrorMessage
                      name="telefone"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="cnpj"
                      className="form-control input-lg"
                      placeholder="CNPJ"
                      value={formatCNPJ(values.cnpj)}
                    />
                    <ErrorMessage
                      name="cnpj"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="municipio"
                      className="form-control input-lg"
                      placeholder="Cidade"
                    />
                    <ErrorMessage
                      name="municipio"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      as="select"
                      name="segmento"
                      className="form-control input-lg"
                    >
                      <option value="">Selecione o segmento</option>
                      {this.state.categorias &&
                        this.state.categorias.map((categoria, index) => (
                          <option key={index} value={categoria.nome}>
                            {categoria.nome}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage
                      name="segmento"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <h3
                    className="text-center"
                    style={{
                      color:
                        this.state.plano === "anoteja" ? "#FFFFFF" : "#000000",
                    }}
                  >
                    Dados de acesso
                  </h3>
                  <div className="form-group">
                    <Field
                      type="email"
                      name="email"
                      className="form-control input-lg"
                      placeholder="E-mail da loja"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xs-6">
                        <Field
                          type="password"
                          name="senha"
                          className="form-control input-lg"
                          placeholder="Senha"
                        />
                        <ErrorMessage
                          name="senha"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-xs-6">
                        <Field
                          type="password"
                          name="confirmarSenha"
                          className="form-control input-lg"
                          placeholder="Confirmar senha"
                        />
                        <ErrorMessage
                          name="confirmarSenha"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-vk btn-lg btn-block"
                    disabled={isSubmitting}
                  >
                    Começar a vender
                  </button>
                </Form>
              )}
            </Formik>
          </section>
        </div>
      </div>
    );
  }
}

export default Parceiro;
