import React, { Component } from "react";
import "./Perfil.css";
import MobilePreview from "./MobilePreview";
import EstabelecimentoContext from "../../context/EstabelecimentoContext";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import { formatPhone, formatTime } from "../../utils/formatters";
import ColorSelect from "./ColorSelect";
import PropTypes from "prop-types";

class Perfil extends Component {
  static contextType = EstabelecimentoContext;

  constructor(props) {
    super(props);
    this.state = {
      estabelecimento: {
        razao_social: "",
        municipio: "",
        email: "",
        senha: "",
        telefone: "",
        confirmarSenha: "",
        horario: {
          horario_Domingo: "08:00|20:00",
          horario_Segunda: "08:00|20:00",
          horario_Terca: "08:00|20:00",
          horario_Quarta: "08:00|20:00",
          horario_Quinta: "08:00|20:00",
          horario_Sexta: "08:00|20:00",
          horario_Sabado: "08:00|20:00",
        },
      },
      selectedColors: {
        a: "#FF5722",
        b: "#FE8862",
        c: "#FFBAA4",
        d: "#FFE6DE",
        e: "#FFE6DE",
      },
      imageUrl: null,
      errors: {},
    };
  }

  componentDidMount() {
    const { estabelecimento } = this.context;
    this.setState({
      estabelecimento: {
        ...estabelecimento,
        senha: "",
        confirmarSenha: "",
      },
      imageUrl: estabelecimento.imagem || null,
    });
  }

  handleColorSelect = (colors) => {
    this.setState({ selectedColors: colors });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name.startsWith("horario_")) {
      formattedValue = formatTime(value);
      this.updateEstabelecimentoState(name, formattedValue, true);
    } else {
      this.updateEstabelecimentoState(name, formattedValue);
    }
  };

  updateEstabelecimentoState = (name, value, isHorario = false) => {
    this.setState((prevState) => ({
      estabelecimento: {
        ...prevState.estabelecimento,
        [isHorario ? "horario" : name]: isHorario
          ? { ...prevState.estabelecimento.horario, [name]: value }
          : value,
      },
    }));
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ imageUrl: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  validateTimeInput = (value) => {
    const regex =
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]\|([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(value);
  };

  validateForm = () => {
    const { estabelecimento } = this.state;
    const errors = {};

    if (!estabelecimento.razao_social) {
      errors.razao_social = "O nome da loja é obrigatório.";
    }

    if (!estabelecimento.municipio) {
      errors.municipio = "A cidade é obrigatória.";
    }

    if (!estabelecimento.email) {
      errors.email = "O e-mail é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(estabelecimento.email)) {
      errors.email = "O e-mail é inválido.";
    }

    if (!estabelecimento.telefone) {
      errors.telefone = "O telefone é obrigatório.";
    }

    if (estabelecimento.senha !== estabelecimento.confirmarSenha) {
      errors.senha = "As senhas não coincidem.";
    } else if (estabelecimento.senha && estabelecimento.senha.length < 6) {
      errors.senha = "A senha deve ter pelo menos 6 caracteres.";
    }

    const isValidTime = Object.values(estabelecimento.horario).every((time) =>
      this.validateTimeInput(time)
    );

    if (!isValidTime) {
      errors.horario =
        "Por favor, insira horários válidos no formato 08:00|20:00.";
    }

    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      Object.values(errors).forEach((error) => toast.error(error));
      return;
    }

    const { updateEstabelecimento } = this.context;
    const { estabelecimento } = this.state;

    const estabelecimentoToUpdate = { ...estabelecimento };
    delete estabelecimentoToUpdate.confirmarSenha;

    updateEstabelecimento(estabelecimentoToUpdate);
  };

  render() {
    const { estabelecimento, selectedColors, imageUrl, errors } = this.state;

    return (
      <div className="container-fluid">
        <div className="container page-config">
          <div className="row">
            <section className="col-md-3">
              <h3 className="text-center">Configurar minha loja</h3>
              <div className="circle-border">
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="Preview"
                    className="img-circle img-responsive"
                  />
                ) : (
                  <div className="input-img">
                    <span
                      className="glyphicon glyphicon-camera"
                      aria-hidden="true"
                    ></span>
                    <label htmlFor="file">Upload Foto</label>
                    <input
                      type="file"
                      id="file"
                      onChange={this.handleImageChange}
                    />
                  </div>
                )}
              </div>
              {imageUrl && (
                <button
                  className="btn"
                  onClick={() => this.setState({ imageUrl: null })}
                >
                  Mudar Imagem
                </button>
              )}
              <div className="center-block">
                <ColorSelect onColorSelect={this.handleColorSelect} />
              </div>
              <button className="button-circle button-common">
                Visualizar cardápio ao vivo
              </button>
            </section>
            <section className="col-md-4">
              <h3 className="text-center">Visualizador</h3>
              <MobilePreview colors={selectedColors} imageUrl={imageUrl} />
            </section>
            <section className="col-md-5 section-register">
              <form onSubmit={this.handleSubmit}>
                <div className="form-horizontal">
                  <h3 className="text-center">Cadastro</h3>
                  <div className="form-group">
                    <input
                      placeholder="Nome da loja"
                      name="razao_social"
                      className={`form-control input-lg ${
                        errors.razao_social ? "is-invalid" : ""
                      }`}
                      value={estabelecimento.razao_social}
                      onChange={this.handleInputChange}
                      required
                    />
                    {errors.razao_social && (
                      <div className="invalid-feedback">
                        {errors.razao_social}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Cidade"
                      name="municipio"
                      className={`form-control input-lg ${
                        errors.municipio ? "is-invalid" : ""
                      }`}
                      value={estabelecimento.municipio}
                      onChange={this.handleInputChange}
                      required
                    />
                    {errors.municipio && (
                      <div className="invalid-feedback">{errors.municipio}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="telefone"
                      className={`form-control input-lg ${
                        errors.telefone ? "is-invalid" : ""
                      }`}
                      placeholder="Tel. WhatsApp"
                      value={formatPhone(estabelecimento.telefone) || ""}
                      onChange={this.handleInputChange}
                      required
                    />
                    {errors.telefone && (
                      <div className="invalid-feedback">{errors.telefone}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <h3 className="text-center col-lg-12">
                    Horário de atendimento
                  </h3>
                  {Object.keys(estabelecimento.horario).map((key) => (
                    <div className="col-xs-6" key={key}>
                      <label htmlFor={key}>
                        {key.replace("horario_", "").replace("_", " ")}
                      </label>
                      <InputMask
                        mask="99:99|99:99"
                        className={`form-control input-lg ${
                          errors.horario ? "is-invalid" : ""
                        }`}
                        type="text"
                        placeholder="08:00|20:00"
                        name={key}
                        value={formatTime(estabelecimento.horario[key])}
                        onChange={this.handleInputChange}
                        required
                      />
                      {errors.horario && (
                        <div className="invalid-feedback">{errors.horario}</div>
                      )}
                    </div>
                  ))}
                </div>

                <h3 className="text-center col-lg-12">Dados de acesso</h3>
                <div className="form-horizontal">
                  <div className="form-group">
                    <input
                      placeholder="E-mail da loja"
                      name="email"
                      className={`form-control input-lg ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      value={estabelecimento.email}
                      onChange={this.handleInputChange}
                      required
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xs-6">
                        <input
                          placeholder="Senha"
                          name="senha"
                          type="password"
                          className={`form-control input-lg ${
                            errors.senha ? "is-invalid" : ""
                          }`}
                          value={estabelecimento.senha}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-xs-6">
                        <input
                          placeholder="Confirmar senha"
                          name="confirmarSenha"
                          type="password"
                          className={`form-control input-lg ${
                            errors.senha ? "is-invalid" : ""
                          }`}
                          value={estabelecimento.confirmarSenha}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    {errors.senha && (
                      <div className="invalid-feedback">{errors.senha}</div>
                    )}
                  </div>
                </div>
                <button className="button-form button-common">Salvar</button>
              </form>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Perfil.propTypes = {
  context: PropTypes.shape({
    estabelecimento: PropTypes.object.isRequired,
    updateEstabelecimento: PropTypes.func.isRequired,
  }),
};

export default Perfil;
