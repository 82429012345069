import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";
import InputMask from "react-input-mask";
import { geocodeAddress } from "./geocodeUtils";
import { toast } from "react-hot-toast";

const TaxaEntregaGeo = ({ taxa, onChangeLocation }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const [selectedRadius, setSelectedRadius] = useState(1000);
  const [searchLocation, setSearchLocation] = useState("");
  const [searchedLocation, setSearchedLocation] = useState(null);

  const position = { lat: -23.0882, lng: -47.2234 };

  const { isLoaded: mapsLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAaRG_Q93Makyp9Dt_n_r_fyYGd3g91yIo",
  });

  useEffect(() => {
    setIsLoaded(mapsLoaded);
  }, [taxa, mapsLoaded]);

  const handleSearch = async () => {
    try {
      const result = await geocodeAddress(searchLocation);

      if (result) {
        setSearchedLocation(result);
        onChangeLocation(result);
      }
    } catch (error) {
      toast.error("Erro ao obter coordenadas:", {
        id: "google-map-script",
      });
      console.error("Erro ao obter coordenadas:", error);
    }
  };

  const locationToUse = searchedLocation || position;

  return (
    <>
      <div className="form-group col-sm-6">
        <label>Descrição:</label>
        <input
          type="text"
          name="bairro"
          defaultValue={taxa.bairro}
          className="form-control input-sm"
          placeholder=""
          required
        />
      </div>
      <div className="form-group col-sm-6">
        <label htmlFor="cep_inicio" className="control-label">
          Localização (Cep)
        </label>
        <div className="row col-sm-12" style={{ display: "flex" }}>
          <InputMask
            mask="99999-999"
            type="text"
            name="cep_inicio"
            id="cep_inicio"
            className="form-control input-sm"
            placeholder="Digite o CEP"
            defaultValue={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            type="button"
            onClick={handleSearch}
            className="btn btn-sm btn-vk"
            aria-label="Pesquisar Localização pelo CEP"
          >
            Pesquisar
          </button>
        </div>
      </div>

      <div className="col-sm-4"></div>
      <div className="form-group col-md-12">
        <label>Raio (Km)</label>
        <select
          name="distancia"
          className="form-control input-sm"
          placeholder=""
          defaultValue={selectedRadius}
          onChange={(e) => {
            e.preventDefault();
            setSelectedRadius(Number(e.target.value));
          }}
        >
          <option value={1000}>1 quilometro</option>
          <option value={2000}>2 quilometros</option>
          <option value={5000}>5 quilometros</option>
          <option value={10000}>10 quilometros</option>
          <option value={20000}>20 quilometros</option>
          <option value={40000}>40 quilometros</option>
          <option value={60000}>60 quilometros</option>
          <option value={80000}>80 quilometros</option>
          <option value={100000}>100 quilometros</option>
          <option value={250000}>250 quilometros</option>
          <option value={500000}>500 quilometros</option>
        </select>
      </div>
      <div className="container-fluid mapaSvg">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ height: "300px", width: "100%" }}
            center={locationToUse}
            zoom={14}
          >
            {isLoaded && (
              <>
                <Marker
                  position={searchedLocation}
                  options={{
                    label: {
                      className: "map-marker",
                    },
                  }}
                />
                <Circle
                  center={searchedLocation}
                  radius={selectedRadius}
                  options={{
                    strokeColor: "#FF5722",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF7043",
                    fillOpacity: 0.35,
                  }}
                />
              </>
            )}
          </GoogleMap>
        ) : (
          <p>Carregando mapa...</p>
        )}
      </div>
    </>
  );
};

TaxaEntregaGeo.propTypes = {
  taxa: PropTypes.object.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
};

export default TaxaEntregaGeo;
