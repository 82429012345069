import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import ModalComponentItem from "./ModalComponentItem";

import "./styles.css";

class ComponentItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    ocultar: PropTypes.bool.isRequired,
    getCatComplementos: PropTypes.func,
    selectComp: PropTypes.func,
  };

  state = {
    isModalVisible: false,
  };

  showModalComplement = () => {
    this.setState({ isModalVisible: true });
  };

  render() {
    const { item, ocultar } = this.props;
    const { isModalVisible } = this.state;

    return (
      <div>
        {ocultar ? (
          <div className="cat-complement-geral-item">
            <br />
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-5">
                  <h4>{item.nome_complemento}</h4>
                </div>
                <div className="col-sm-3">
                  <h5>
                    Preço:
                    <b>
                      {` ${item.vr_adicional.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}`}
                    </b>
                  </h5>
                </div>
                <div className="col-sm-2">
                  <h5>
                    Cód:
                    <b>
                      {` ${item.cod_externo === "" ? "Não" : item.cod_externo}`}
                    </b>
                  </h5>
                </div>
                <div className="col-sm-2">
                  <h5>
                    Sequência: <b>{` ${item.sequencia}`}</b>
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9 text-muted">
                  <h5>
                    {item.descricao_complemento === ""
                      ? "Item sem descrição"
                      : item.descricao_complemento}
                  </h5>
                </div>
                <div className="col-sm-2">
                  <button
                    className="form-control input-md btn-vk-edit"
                    onClick={() => this.props.selectComp(item)}
                  >
                    Disponibilidade
                  </button>
                </div>
                <div className="col-sm-1">
                  <center>
                    <button
                      className="form-control input-md btn-vk-edit"
                      onClick={this.showModalComplement}
                      style={{ width: "60%" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>
                    </button>
                  </center>
                </div>
              </div>
            </div>

            <Modal
              ariaHideApp={false}
              isOpen={isModalVisible}
              style={customStyles}
              contentLabel="Modal Complemento Item"
              onRequestClose={() => this.setState({ isModalVisible: false })}
            >
              <ModalComponentItem
                modo={"editar"}
                isOpen={true}
                ocultar={isModalVisible}
                onClosed={() => {
                  this.setState({ isModalVisible: false });
                  this.props.getCatComplementos(item);
                }}
                item={item}
                getCatComplementos={() => this.props.getCatComplementos(item)}
              />
            </Modal>
            <br />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ComponentItem;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};
