import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as PrestAPI from "../../service/api/PrestAPI";
import GrupoList from "./GrupoList";
import CategoryOrder from "./CategoriaOrder";

class ProdutoGrupoList extends Component {
  state = {
    grupos: [],
    viewMode: "list",
  };

  componentDidMount() {
    const estabelecimento = this.props.estabelecimento;

    PrestAPI.get(
      "ProdutoGrupo",
      `estab=${estabelecimento.id_estabelecimento}`
    ).then((value) => {
      this.setState({ grupos: value.categorias });
    });
  }

  handleViewModeChange = (mode) => {
    this.setState({ viewMode: mode });
  };

  render() {
    const { editGrupo } = this.props;
    const { grupos, viewMode } = this.state;

    return (
      <div className="container-fluid container">
        <div className="row">
          <div className="col-sm-9">
            <h3>Categorias</h3>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-4">
            <button
              className="btn btn-vk row mb-2"
              onClick={() => this.handleViewModeChange("list")}
            >
              <span
                class="glyphicon glyphicon-th-list"
                aria-hidden="true"
              ></span>
              Listar Categorias
            </button>
          </div>
          <div className="col-sm-4">
            <button
              className="btn btn-vk"
              onClick={() => this.handleViewModeChange("order")}
            >
              <span
                class="glyphicon glyphicon-signal"
                aria-hidden="true"
              ></span>
              Ordenar Categorias
            </button>
          </div>
          <div className="col-sm-4">
            <Link to="/produto_grupo-add" className="btn btn-vk">
              <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
              Adicionar Nova Categoria
            </Link>
          </div>
        </div>
        <div className="container-fluid" style={{ marginTop: "85px" }}>
          {viewMode === "list" && (
            <GrupoList categorias={grupos} editGrupo={editGrupo} />
          )}
          {viewMode === "order" && <CategoryOrder categorias={grupos} />}
        </div>
      </div>
    );
  }
}

export default ProdutoGrupoList;
