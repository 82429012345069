export const formatCNPJ = (value) => {
  return (
    value &&
    value
      .replace(/\D/g, "") // Remove todos os caracteres não numéricos
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  );
};

export const formatIE = (value) => {
  return (
    value &&
    value
      .replace(/\D/g, "") // Remove todos os caracteres não numéricos
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\.\d{3})\d+?$/, "$1")
  );
};

export const formatPhone = (value) => {
  if (value) {
    value = value.replace(/\D/g, "");
    if (value.length > 10) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (value.length > 5) {
      value = value.replace(/^(\d{2})(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (value.length > 2) {
      value = value.replace(/^(\d{2})(\d{0,5})/, "($1) $2");
    } else {
      value = value.replace(/^(\d*)/, "($1");
    }
  }
  return value;
};

export const formatTime = (value) => {
  const timeParts = value.split("|");
  if (timeParts.length !== 2) return value;

  const formatPart = (part) => {
    const [hours, minutes] = part.split(":");
    const formattedHours = hours.padStart(2, "0");
    const formattedMinutes = minutes.padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  return `${formatPart(timeParts[0])}|${formatPart(timeParts[1])}`;
};

export const formatCurrencyBRL = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};
