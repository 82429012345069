import React from "react";
import PropTypes from "prop-types";

class InputField extends React.Component {
  render() {
    const { className, name, label, ...inputProps } = this.props;

    return (
      <div className={className}>
        <label htmlFor={name}>{label}</label>
        <input
          {...inputProps}
          className="form-control"
          name={name}
          id={name}
        />
      </div>
    );
  }
}

InputField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

InputField.defaultProps = {
  className: "",
};

export default InputField;
