import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../service/api/PrestAPI";
import "./Pedidos.css";
import { formatCurrencyBRL } from "../../utils/formatters";

const statusMap = {
  PENDENTE: "CONFIRMADO",
  CONFIRMADO: "ENVIADO",
  ENVIADO: "ENTREGUE",
};

class Pedidos extends Component {
  static propTypes = {
    estabelecimento: PropTypes.object.isRequired,
    pedidos: PropTypes.array.isRequired,
    setConfirmaPedido: PropTypes.func,
    unsetConfirmaPedido: PropTypes.func,
  };

  state = {
    pedido: {},
    documentoHide: "",
    itens: [],
    modoPedido: "ABERTO",
    subtotal: 0,
  };

  setPedido = (pedido) => {
    PrestAPI.get("Pedidos", `id_pedido=${pedido.id_pedido}`).then((value) => {
      let documento = pedido.documento;

      if (documento !== null && documento !== undefined && documento !== "") {
        if (documento.length === 14) {
          documento = documento.substr(0, 5);
          documento = documento += "**.***-**";
          this.setState({ documentoHide: documento });
        } else {
          documento = documento.substr(0, 4);
          documento = documento += "**.***/****-**";
          this.setState({ documentoHide: documento });
        }
      } else {
        this.setState({ documentoHide: "" });
      }

      this.setState({ pedido });
      this.setState({ itens: value });
      this.calSubtotal();
    });
  };
  calSubtotal = () => {
    const { itens } = this.state;

    const subtotal = itens.reduce((soma, item) => {
      const totalSubItens = item.vr_total_sub_itens || 0;
      const preco = (item.vr_unitario + totalSubItens) * item.quantidade;
      return soma + preco;
    }, 0);

    this.setState({ subtotal });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  updateStatusPedido = async (pedido, statusPedido) => {
    pedido.status_pedido = statusPedido;
    await PrestAPI.update("Pedido", `?id_pedido=${pedido.id_pedido}`, pedido)
      .then((data) => {
        if (data.rows_affected !== 0) {
          this.setState({ pedido: pedido });
        }
      })
      .catch((error) => {
        alert(error);
      });

    PrestAPI.get(
      "Pedidos",
      `estab=${this.props.estabelecimento.id_estabelecimento}`
    )
      .then((value) => {
        for (let i = 0; i < value.pedidos.length; i++) {
          if (value.pedidos[i].status_pedido === "PENDENTE") {
            this.props.setConfirmaPedido();
            this.setState({ pedidos: [] });
            this.setState({ pedidos: value.pedidos });
            return;
          } else {
            this.props.unsetConfirmaPedido();
            this.setState({ pedidos: [] });
            this.setState({ pedidos: value.pedidos });
          }
        }
      })
      .catch((erro) => {
        alert(erro);
      });
  };

  renderSwitch(statusPedido) {
    const statusConfig = {
      CONFIRMADO: { border: "1px solid DeepSkyBlue", text: "Confirmado" },
      ENVIADO: { border: "1px solid gray", text: "Enviado" },
      ENTREGUE: { border: "1px solid green", text: "Entregue" },
      CANCELADO: { border: "1px solid red", text: "Cancelado" },
      default: { border: "1px solid orange", text: "Pendente" },
    };

    const { border, text } = statusConfig[statusPedido] || statusConfig.default;

    return (
      <h4
        style={{
          border,
          borderRadius: "5px",
          textAlign: "center",
          margin: "20px, 0px, 10px",
        }}
      >
        {text}
      </h4>
    );
  }

  mudaNome(statusPedido) {
    const statusConfig = {
      CONFIRMADO: { border: "1px solid DeepSkyBlue", text: "Confirmado" },
      ENVIADO: { border: "1px solid gray", text: "Enviado" },
      ENTREGUE: { border: "1px solid green", text: "Entregue" },
      CANCELADO: { border: "1px solid red", text: "Cancelado" },
      default: { border: "1px solid orange", text: "Pendente" },
    };

    const { border, text } = statusConfig[statusPedido] || statusConfig.default;

    return (
      <div
        className="col-xs-12"
        style={{
          border,
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {text}
      </div>
    );
  }
  verificaStatus = (pedido) => {
    const { modoPedido } = this.state;
    const isAberto = modoPedido === "ABERTO";

    return isAberto
      ? pedido.status_pedido !== "CANCELADO" &&
          pedido.status_pedido !== "ENTREGUE"
      : pedido.status_pedido === "CANCELADO" ||
          pedido.status_pedido === "ENTREGUE";
  };

  render() {
    const { pedidos } = this.props;
    const { pedido, documentoHide, itens, subtotal, modoPedido } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div
            className="panel panel-default col-sm-4 col-xs-12"
            style={{
              maxHeight: "85vh",
              minHeight: "85vh",
              overflow: "auto",
              borderRadius: "3px 0px 0px 3px",
            }}
          >
            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col-xs-6" style={{ margin: "0", padding: "0" }}>
                <button
                  className="btn btn-block"
                  style={{
                    outline: "none",
                    borderRadius: "2px 0px 0px 0px",
                    borderBottom: `${
                      modoPedido === "ABERTO" ? "solid 3px #ff5722" : ""
                    }`,
                    borderRight: "none",
                  }}
                  onClick={() => this.setState({ modoPedido: "ABERTO" })}
                >
                  {modoPedido === "ABERTO" ? (
                    <b style={{ color: "#ff5722" }}>Aberto</b>
                  ) : (
                    <>Aberto</>
                  )}
                </button>
              </div>
              <div className="col-xs-6" style={{ margin: "0", padding: "0" }}>
                <button
                  className="btn btn-block"
                  style={{
                    outline: "none",
                    borderRadius: "0px 0px 0px 2px",
                    borderBottom: `${
                      modoPedido !== "ABERTO" ? "solid 3px #ff5722" : ""
                    }`,
                    borderLeft: "none",
                  }}
                  onClick={() => this.setState({ modoPedido: "FINALIZADO" })}
                >
                  {modoPedido !== "ABERTO" ? (
                    <b style={{ color: "#ff5722" }}>Finalizado</b>
                  ) : (
                    <>Finalizado</>
                  )}
                </button>
              </div>
            </div>

            {pedidos
              .filter((pedido) => this.verificaStatus(pedido))
              .map((pedido) => (
                <div
                  className="panel panel-default clickable pedido"
                  key={pedido.id_pedido}
                  onClick={() => this.setPedido(pedido)}
                  style={{
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                    backgroundColor: `${
                      pedido.status_pedido === "PENDENTE"
                        ? "#fcee9f"
                        : pedido.status_pedido === "ENTREGUE"
                        ? "#abfc8b"
                        : pedido.status_pedido === "CANCELADO"
                        ? "#fc9797"
                        : ""
                    }`,
                  }}
                >
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-xs-2">{pedido.id_pedido}</div>
                      <div className="col-xs-5" style={{ padding: "0px" }}>
                        {pedido.nome_cliente}
                      </div>
                      <div className="col-xs-5">
                        <div className="col-xs-12">
                          {this.mudaNome(pedido.status_pedido)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {Object.keys(pedido).length !== 0 ? (
            <div
              className="panel panel-default col-sm-8 col-xs-12"
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflow: "auto",
                padding: "30px 80px 30px 80px",
                borderRadius: "0px 3px 3px 0px",
                borderLeft: "none",
              }}
            >
              <div className="row">
                <div
                  className="col-sm-3"
                  style={{
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                  }}
                >
                  {this.renderSwitch(pedido.status_pedido)}
                </div>
                <div className="col-xs-6" style={{ textAlign: "center" }}>
                  <h3>
                    <b>{`Pedido ${pedido.id_pedido}`}</b>
                  </h3>
                  {pedido.tipo_pedido === "RETIRADA" ? (
                    <h4 style={{ color: "red" }}>Pedido para retirada</h4>
                  ) : null}
                </div>
                <div className="col-xs-3">
                  <span style={{ fontSize: "15px" }}>
                    {new Date(pedido.data_hora).toLocaleString("pt-BR", {
                      timeZone: "GMT",
                    })}
                  </span>
                </div>
              </div>
              {pedido.id_pedido && (
                <div className="row">
                  {pedido.data_hora_agendamento && (
                    <div className="col-md-12" style={{ textAlign: "left" }}>
                      <b>Data de encomenda: </b>
                      <div style={{ fontSize: "15px" }}>
                        {new Date(pedido.data_hora_agendamento).toLocaleString(
                          "pt-BR",
                          {
                            timeZone: "GMT",
                          }
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      pedido.telefone !== "" ? "col-sm-9" : "col-sm-12"
                    }
                    style={{ textAlign: "left" }}
                  >
                    <h5 style={{ margin: "0" }}>
                      <b>Cliente:</b>
                      <div style={{ fontSize: "16px" }}>
                        {` ${pedido.nome_cliente}`}
                      </div>
                    </h5>
                  </div>
                  {pedido.telefone !== "" ? (
                    <div className="col-sm-3" style={{ textAlign: "left" }}>
                      <b>Telefone:</b>
                      <div style={{ fontSize: "16px" }}>{pedido.telefone}</div>
                    </div>
                  ) : null}
                  <div
                    className={pedido.documento ? "col-sm-9" : "col-sm-12"}
                    style={{ textAlign: "left" }}
                  >
                    <h5 style={{ marginTop: "10px", marginBottom: "0px" }}>
                      <b>Endereço:</b>
                      {(pedido.entrega_logradouro || pedido.entrega_numero) && (
                        <div style={{ fontSize: "16px" }}>
                          {pedido.entrega_logradouro || ""}
                          {pedido.entrega_logradouro && pedido.entrega_numero
                            ? ", "
                            : ""}
                          {pedido.entrega_numero || ""}
                        </div>
                      )}
                      {(pedido.entrega_bairro ||
                        pedido.entrega_municipio ||
                        pedido.entrega_uf) && (
                        <div style={{ fontSize: "16px" }}>
                          {pedido.entrega_bairro || ""}
                          {pedido.entrega_bairro && pedido.entrega_municipio
                            ? " - "
                            : ""}
                          {pedido.entrega_municipio || ""}
                          {(pedido.entrega_bairro ||
                            pedido.entrega_municipio) &&
                          pedido.entrega_uf
                            ? ", "
                            : ""}
                          {pedido.entrega_uf || ""}
                        </div>
                      )}
                      {pedido.entrega_complemento && (
                        <div style={{ fontSize: "16px" }}>
                          {pedido.entrega_complemento}
                        </div>
                      )}
                    </h5>
                  </div>
                  {pedido.documento && (
                    <>
                      <div className="w-100"></div>
                      <div className="col-sm-3" style={{ textAlign: "left" }}>
                        <h5 style={{ marginTop: "10px", marginBottom: "0px" }}>
                          <b>Documento:</b>
                          <div style={{ fontSize: "16px" }}>
                            {documentoHide}
                          </div>
                        </h5>
                      </div>
                    </>
                  )}
                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                  <div className="container-fluid col-sm-12">
                    {itens.map((item) => (
                      <div
                        className="panel panel-default"
                        style={{
                          border: "none",
                          boxShadow: "none",
                          width: "100%",
                        }}
                        key={item.num_item}
                      >
                        <div className="panel-body" style={{ padding: "0" }}>
                          <div className="row">
                            <div
                              className="col-xs-10"
                              style={{ fontSize: "15px" }}
                            >{`${item.quantidade}x ${item.nome_produto}`}</div>
                            <div
                              className="col-xs-2"
                              style={{ textAlign: "end" }}
                            >
                              {formatCurrencyBRL(item.vr_unitario)}
                            </div>
                            {item.subItens &&
                              item.subItens.map(
                                (complemento) =>
                                  complemento && (
                                    <div
                                      key={complemento.id_complemento}
                                      className="col-xs-12"
                                      style={{ paddingRight: "0" }}
                                    >
                                      <div
                                        className="col-xs-9 text-muted"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {` ${complemento.quantidade}x ${complemento.complemento}`}
                                      </div>
                                      <div
                                        className="col-xs-3 text-muted"
                                        style={{
                                          fontSize: "12px",
                                          textAlign: "end",
                                        }}
                                      >
                                        {formatCurrencyBRL(
                                          complemento.vr_adicional
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}
                            <div className="col-xs-10"></div>
                            <div
                              className="col-xs-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                textAlign: "end",
                              }}
                            >
                              {item.vr_total_sub_itens
                                ? formatCurrencyBRL(
                                    (item.vr_unitario +
                                      item.vr_total_sub_itens) *
                                      item.quantidade
                                  )
                                : formatCurrencyBRL(item.vr_total)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      className="panel panel-default"
                      style={{
                        border: "none",
                        width: "100%",
                        boxShadow: "none",
                      }}
                    >
                      <div className="row">
                        <div className="col-xs-8"></div>
                        <div className="col-xs-2" style={{ fontSize: "16px" }}>
                          <b>Subtotal:</b>
                        </div>
                        <div
                          className="col-xs-2"
                          style={{ fontSize: "16px", textAlign: "end" }}
                        >
                          <b>{formatCurrencyBRL(subtotal)}</b>
                        </div>
                        <div className="col-xs-8"></div>
                        <div className="col-xs-2" style={{ fontSize: "16px" }}>
                          <b>Taxa:</b>
                        </div>
                        <div
                          className="col-xs-2"
                          style={{ fontSize: "16px", textAlign: "end" }}
                        >
                          <b>{formatCurrencyBRL(pedido.entrega_taxa)}</b>
                        </div>
                        <div className="col-xs-8"></div>
                        <div className="col-xs-2" style={{ fontSize: "16px" }}>
                          <b>Total:</b>
                        </div>
                        <div
                          className="col-xs-2"
                          style={{ fontSize: "16px", textAlign: "end" }}
                        >
                          <b>{formatCurrencyBRL(pedido.vr_pedido)}</b>
                        </div>
                        {pedido.vr_troco !== 0 ? (
                          <>
                            <div className="col-xs-8"></div>
                            <div
                              className="col-xs-2"
                              style={{ fontSize: "16px", paddingRight: "0px" }}
                            >
                              <b>Troco para:</b>
                            </div>
                            <div
                              className="col-xs-2"
                              style={{ fontSize: "16px", textAlign: "end" }}
                            >
                              <b>{formatCurrencyBRL(pedido.vr_troco)}</b>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="panel panel-default"
                      style={{
                        border: "none",
                        width: "100%",
                        boxShadow: "none",
                      }}
                    >
                      <div className="col-xs-12">
                        <b>Forma de Pagamento:</b>
                      </div>
                      <div className="col-xs-12">{pedido.forma_pagamento}</div>
                      {pedido.entrega_observacao && (
                        <>
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "left", marginTop: "36px" }}
                          >
                            <h5
                              style={{ marginTop: "10px", marginBottom: "0px" }}
                            >
                              <b>Observações:</b>
                            </h5>
                          </div>
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "left" }}
                          >
                            <div style={{ fontSize: "16px" }}>
                              {pedido.entrega_observacao}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                  <div
                    className="panel panel-default"
                    style={{
                      border: "none",
                      width: "100%",
                      boxShadow: "none",
                    }}
                  >
                    <div className="col-xs-1"></div>
                    <div className="col-xs-3">
                      {pedido.status_pedido !== "CANCELADO" &&
                      pedido.status_pedido !== "ENTREGUE" &&
                      pedido.status_pedido !== "ENVIADO" ? (
                        <button
                          className="btn btn-default btn-block"
                          style={{
                            textAlign: "center",
                          }}
                          onClick={() =>
                            this.updateStatusPedido(pedido, "CANCELADO")
                          }
                        >
                          <>
                            <span>
                              <div className="col-xs-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                              </div>
                              <div
                                className="col-xs-8"
                                style={{ marginLeft: "0" }}
                              >
                                <b>Cancelar</b>
                              </div>
                            </span>
                          </>
                        </button>
                      ) : null}
                    </div>
                    <div className="col-xs-4"></div>
                    <div className="col-xs-3">
                      {pedido.status_pedido !== "CANCELADO" &&
                      pedido.status_pedido !== "ENTREGUE" ? (
                        <button
                          className="btn btn-vk btn-block"
                          style={{ outline: "none" }}
                          onClick={() => {
                            const novoStatus =
                              statusMap[pedido.status_pedido] || "ENTREGUE";
                            this.updateStatusPedido(pedido, novoStatus);
                          }}
                        >
                          {pedido.status_pedido === "PENDENTE" ? (
                            <>
                              <span>
                                <div className="col-xs-4">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-check2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                  </svg>
                                </div>
                                <div
                                  className="col-xs-8"
                                  style={{ marginLeft: "0" }}
                                >
                                  <b>Confirmar</b>
                                </div>
                              </span>
                            </>
                          ) : pedido.status_pedido === "CONFIRMADO" ? (
                            <>
                              <div className="col-xs-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                </svg>
                              </div>
                              <div
                                className="col-xs-8"
                                style={{ marginLeft: "0" }}
                              >
                                <b>Despachar</b>
                              </div>
                            </>
                          ) : pedido.status_pedido === "ENVIADO" ? (
                            <>
                              <div className="col-xs-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check2"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                </svg>
                              </div>
                              <div
                                className="col-xs-8"
                                style={{ marginLeft: "0" }}
                              >
                                <b>Concluir</b>
                              </div>
                            </>
                          ) : null}
                        </button>
                      ) : null}
                      <div className="col-xs-1"></div>
                    </div>
                  </div>
                </div>
              )}

              {/* <Panel>
                <Panel.Heading>
                  <Panel.Title>Alterar Status</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div
                    className="btn-group btn-group-justified"
                    role="group"
                    aria-label="..."
                  >
                    {pedido.status_pedido === "PENDENTE" ? (
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            this.updateStatusPedido(pedido, "CONFIRMADO")
                          }
                        >
                          Confirmar
                        </button>
                      </div>
                    ) : (
                      <div className="btn-group" role="group" hidden></div>
                    )}
                    {pedido.status_pedido === "CONFIRMADO" ? (
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={() =>
                            this.updateStatusPedido(pedido, "ENVIADO")
                          }
                        >
                          Enviar
                        </button>
                      </div>
                    ) : (
                      <div className="btn-group" role="group" hidden></div>
                    )}
                    {pedido.status_pedido === "ENVIADO" ? (
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() =>
                            this.updateStatusPedido(pedido, "ENTREGUE")
                          }
                        >
                          Entregue
                        </button>
                      </div>
                    ) : (
                      <div className="btn-group" role="group" hidden></div>
                    )}
                    {pedido.status_pedido === "PENDENTE" ||
                    pedido.status_pedido === "CONFIRMADO" ? (
                      <div className="btn-group" role="group">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() =>
                            this.updateStatusPedido(pedido, "CANCELADO")
                          }
                        >
                          Cancelar
                        </button>
                      </div>
                    ) : (
                      <div className="btn-group" role="group" hidden></div>
                    )}
                  </div>
                </Panel.Body>
              </Panel> */}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Pedidos;
