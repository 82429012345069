import React from "react";
import { Component } from "react";
import ModalAgendamentoProduto from "react-modal";
import PropTypes from "prop-types";
import AgendaComponent from "./Components/AgendaComponent";
import ModalAgenda from "./Components/ModalAgenda";
import * as PrestAPI from "../../service/api/PrestAPI";
import "./Style.css";
class AgendamentoProduto extends Component {
  static propTypes = {
    produto: PropTypes.object.isRequired,
    tipo: PropTypes.string.isRequired,
    complemento: PropTypes.object,
    voltar: PropTypes.func,
  };

  state = {
    loading: true,
    isNovaAgenda: false,
    isModalVisible: false,
    agenda: [],
  };

  openModal = () => {
    this.setState({ isModalVisible: true });
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

  editNovaAgenda = () => {
    this.setState({ isNovaAgenda: false });
  };

  componentDidMount = async () => {
    this.setState({ isNovaAgenda: false });

    if (this.props.tipo === "produto") {
      await PrestAPI.get(
        `Produto?id_produto=${this.props.produto.id_produto}&modo=retaguarda`
      )
        .then((result) => {
          if (
            result.produtos[0].agenda !== null ||
            result.produtos[0].agenda !== undefined
          ) {
            let agenda = result.produtos[0].agenda;

            this.setState({ agenda });
          } else {
            this.setState({ agenda: [] });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (this.props.tipo === "complemento") {
      await PrestAPI.get(
        "ProdutoComplemento",
        `estab=${this.props.produto.id_estabelecimento}&idProduto=${this.props.produto.id_produto}&modo=retaguarda`
      )
        .then((result) => {
          var arrComp = [];

          if (
            result.complementos !== null ||
            result.complementos !== undefined
          ) {
            // eslint-disable-next-line
            for (let [_, cat] of result.complementos.entries()) {
              if (
                cat.id_categoria_complemento ===
                this.props.complemento.id_categoria_complemento
              ) {
                for (let i = 0; i < cat.itens.length; i++) {
                  if (
                    cat.itens[i].id_complemento ===
                    this.props.complemento.id_complemento
                  ) {
                    arrComp.push(cat.itens[i]);
                  }
                }
              }
            }
          }
          if (arrComp[0].agenda !== null || arrComp[0].agenda !== undefined) {
            let agenda = arrComp[0].agenda;

            this.setState({ agenda });
          } else {
            this.setState({ agenda: [] });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  setAgenda = (dado) => {
    let informacao = dado;

    this.setState({ agenda: informacao });
    setTimeout(() => {}, 5000);
  };

  render() {
    const { produto, tipo, complemento } = this.props;
    const { isNovaAgenda, isModalVisible, agenda, loading } = this.state;
    return (
      <div className="container-fluid">
        {!loading ? (
          <div className="row">
            <div className="col-sm-2">
              {tipo === "complemento" ? (
                <button
                  className="btn btn-sm btn-default"
                  onClick={() => this.props.voltar()}
                >
                  Voltar
                </button>
              ) : null}
            </div>
            <div className="col-sm-8">
              <div className="col-sm-8">
                {tipo === "produto" ? (
                  <h3>Disponibilidade (Dias da semana)</h3>
                ) : tipo === "complemento" ? (
                  <h3>{`Disponibilidade complemento - ${complemento.nome_complemento}`}</h3>
                ) : null}
              </div>
              <div className="col-sm-4">
                <button
                  className="btn btn-vk btn-md btn-block"
                  style={{ outline: "none", marginTop: "5%" }}
                  onClick={() => {
                    this.openModal();
                    this.setState({ isNovaAgenda: true });
                  }}
                >
                  Nova Agenda
                </button>
              </div>
              <div className="col-sm-12">
                <div
                  className="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  {agenda === null || agenda === undefined
                    ? null
                    : agenda.map((el, index) => (
                        <AgendaComponent
                          key={index}
                          produto={produto}
                          agenda={el}
                          numero={index}
                          editNovaAgenda={this.editNovaAgenda}
                          disponibilidade={el.disponibilidade}
                          setAgenda={(dado) => this.setAgenda(dado)}
                          tipo={this.props.tipo}
                          complemento={complemento}
                        />
                      ))}
                </div>
              </div>
            </div>
            <div className="col-sm-2"></div>
          </div>
        ) : (
          <div className="loader" id="loader" style={{ marginTop: "50px" }}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}

        {/* -------------------MODAL ---------------------------- */}
        <ModalAgendamentoProduto
          ariaHideApp={false}
          isOpen={isModalVisible}
          style={customStyles}
          contentLabel="Modal Agendamento"
          onRequestClose={() => this.hideModal()}
        >
          <ModalAgenda
            hideModal={() => this.hideModal()}
            modo={tipo}
            produto={produto}
            setAgenda={(dado) => this.setAgenda(dado)}
            isNovaAgenda={isNovaAgenda}
            complemento={complemento}
          />
        </ModalAgendamentoProduto>
      </div>
    );
  }
}

export default AgendamentoProduto;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};
