import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import CategoryOrder from "./CategoriaOrder";

class ProdutoGrupoForm extends Component {
  static propTypes = {
    grupo: PropTypes.object.isRequired,
    saveGrupo: PropTypes.func.isRequired,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    const formData = new FormData(form);
    const data = {};

    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    data.id_grupo = Number.parseInt(data.id_grupo, 10);

    data.status = form.elements.status.checked ? 1 : 0;

    if (this.props.saveGrupo) {
      this.props.saveGrupo(data.id_grupo, data);
      toast.success("Salvo!");
    }
  };

  render() {
    const { grupo } = this.props;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="col-md-8">
              <h3>Produto Grupo - Cadastro</h3>
            </div>
            <div className="col-md-4">
              <Link to="/produto_grupo-add" className="btn btn-block btn-vk">
                Novo Grupo
              </Link>
            </div>
            <div className="col-md-12">
              <form onSubmit={this.handleSubmit}>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="row">
                      <div className="form-group col-sm-2">
                        <label>Status:</label>
                        <input
                          type="checkbox"
                          name="status"
                          defaultChecked={grupo.status === 1}
                          className="form-control input-sm"
                        />
                      </div>
                      <div className="form-group col-sm-4">
                        <label>Id.Grupo:</label>
                        <input
                          type="text"
                          name="id_grupo"
                          defaultValue={grupo.id_grupo}
                          className="form-control input-sm"
                          placeholder=""
                          readOnly
                        />
                      </div>
                      <div className="form-group col-sm-4" hidden>
                        <label>Id.Estabelecimento:</label>
                        <input
                          type="text"
                          name="id_estabelecimento"
                          defaultValue={grupo.id_estabelecimento}
                          className="form-control input-sm"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-sm-9">
                        <label>Descrição:</label>
                        <input
                          type="text"
                          name="descricao"
                          defaultValue={grupo.descricao}
                          className="form-control input-sm"
                          placeholder=""
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <Link
                          to="/produto"
                          className="btn btn-default btn-lg btn-block"
                        >
                          Voltar
                        </Link>
                      </div>
                      <div className="form-group col-sm-9">
                        <button className="btn btn-vk btn-lg btn-block">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group col-sm-4"></div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-12">
            {/* <CategoryOrder /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ProdutoGrupoForm;
