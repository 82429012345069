import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({
    ...item,
    nr_sequencia: index + 1,
  }));
};

class CategoryOrder extends Component {
  state = {
    categories: [],
  };

  componentDidMount() {
    this.setState({ categories: this.props.categorias });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categorias !== this.props.categorias) {
      this.setState({ categories: this.props.categorias });
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const categories = reorder(
      this.state.categories,
      result.source.index,
      result.destination.index
    );

    this.setState({
      categories,
    });

    this.saveNewOrder(categories);
  };

  saveNewOrder = (categories) => {
    console.log('Nova ordem salva:', categories);
  
  };

  render() {
    const { categories } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((category, index) => (
                <Draggable key={category.id_grupo} draggableId={String(category.id_grupo)} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'descricao',
                        padding: 16,
                        margin: '0 0 8px 0',
                        backgroundColor: snapshot.isDragging ? '#e0f7fa' : '#fff', // Highlight while dragging
                        border: snapshot.isDragging ? '2px solid #00796b' : '1px solid #ccc',
                        borderRadius: '4px',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {category.descricao} (Ordem: {category.nr_sequencia})
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default CategoryOrder;
